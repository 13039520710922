import { useState, useEffect } from "react";

import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../utils/ThemeManager.jsx";
import { formatMonths } from "../../../utils/chartUtils.js";
// data: // data: cluster.visualization.total_search_volume_by_month
// Search Volume by Month by Type
const SearchVolMonth = ({ data, id = "" }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data == null || Object.keys(data).length === 0) {
      return;
    }

    setOptions(chartOptions(data, id, theme));
  }, [theme]);

  if (data == null || Object.keys(data).length === 0) {
    return (
      <p className="px-4 py-8 font-bold text-center">
        Monthly Search Volume data is not available.
      </p>
    );
  }

  return (
    <ChartDashboard config={options} async={true} id={`monthly-search-volume-${id}`} />
  );
};

export default SearchVolMonth;

const chartOptions = (data, id, theme) => {
  const colors = {
    dark: [
      {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, "rgb(255, 20, 145, 1)"],
          [0.999, "rgb(255, 114, 71, 1)"],
        ],
      },
      {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [[0, "#13306F"]],
      },
    ],
    light: [
      {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [[0, "#0561B7"]],
      },
    ],
  };
  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `monthly-search-volume-cell-${id}`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `monthly-search-volume-cell-${id}`,
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: "column",
            styledMode: false,
            height: 450,
          },
          title: {
            text: "Monthly Search Volume",
          },
          subtitle: {
            text:
              'Source: <a target="_blank" ' +
              'style="color: #999" href="https://semrush.com">SEMRush</a>',
          },
          xAxis: {
            type: "datetime",
            accessibility: {
              rangeDescription: "Range: 12 months.",
            },
            dateTimeLabelFormats: {
              month: "%b", // Display abbreviated month name
            },
            categories: formatMonths(data.search_volume_months),
          },
          yAxis: {
            min: 0,
            title: {
              text: "Monthly Search Volume",
            },
          },
          tooltip: {
            useHTML: true,
            headerFormat: "<table>",
            pointFormat:
              "<tr><th>{point.category}</th></tr>" +
              "<tr><th>Monthly Volume:</th><td>{point.y}</td></tr>",
            footerFormat: "</table>"
          },
          series: [
            {
              name: "Monthly Search Volume",
              data: data.search_volume_monthly,
            },
          ],
        },
      },
    ],
  };
};
