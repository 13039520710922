// AxiosInterceptorSetup.js
import { useEffect } from 'react';
import { $axios } from '../services/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';

const AxiosInterceptorSetup = () => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const interceptor = $axios.interceptors.request.use(
      async (config) => {
        try {
          const token = await getAccessTokenSilently();
          config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
          if (error.error != "login_required") {
            console.error('Error getting access token:', error);
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Cleanup interceptor on unmount
    return () => {
      $axios.interceptors.request.eject(interceptor);
    };
  }, [getAccessTokenSilently]);

  return null;
};

export default AxiosInterceptorSetup;
