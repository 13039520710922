import axios from 'axios';

export const isTokenExpired = (expirationTime) => {
  // Convert the expirationTime to a Date object
  const expirationDate = new Date(expirationTime);

  // Get the current time
  const currentTime = new Date();

  // Compare the expirationDate with the current time
  // If the expirationDate is in the past, the token has expired
  return expirationDate.getTime() < currentTime.getTime();
};

// Function to check if the user has a non-expired token
export const hasNonExpiredToken = (user) => {
  if (!user) {
    return false; // No user, no token
  }

  const { stsTokenManager } = user;
  if (!stsTokenManager || !stsTokenManager.expirationTime) {
    return false; // Missing token expiration information
  }

  // Implement the isTokenExpired function as mentioned before
  const isExpired = isTokenExpired(stsTokenManager.expirationTime);

  return !isExpired;
};

// local instance
export const $axios = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}/django_api/` : 'http://localhost:8000/django_api/',
  headers: { 'Content-Type': 'application/json' },
});

// hypercorn api
export const $axios_old = axios.create({
  baseURL: 'http://localhost:4040',
  headers: {
    'Content-Type': 'application/json',
  },
});

const setupAxiosInterceptors = (_axiosInstance) => {
  _axiosInstance.interceptors.request.use(
    (request) => {
      // const { user } = session.getState();
      // // If you have a token and it's not expired, attach it
      // if (user?.stsTokenManager?.accessToken) {
      //   request.headers['Authorization'] = `Bearer ${user.stsTokenManager.accessToken}`;
      // }
      return request;
    },
    (error) => Promise.reject(error)
  );

  _axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      // const auth = await getAuth();
      // const { clearUser, setUser } = session.getState();

      // // Only handle 401 if we haven't already retried
      // if (error && error.response && error.response.status === 401 && !error.config.__isRetryRequest) {
      //   // First remove the stale token from this request config
      //   delete error.config.headers['Authorization'];
      //   // Also remove from Axios defaults
      //   if (_axiosInstance.defaults.headers.common['Authorization']) {
      //     delete _axiosInstance.defaults.headers.common['Authorization'];
      //   }

      //   error.config.__isRetryRequest = true;
      //   console.log('Token expired, attempting to refresh...');

      //   return new Promise(async (resolve, reject) => {
      //     try {
      //       const currentUser = auth.currentUser;
      //       if (!currentUser) {
      //         clearUser();
      //         return reject(error);
      //       }

      //       // Force-refresh the token
      //       const newAccessToken = await currentUser.getIdToken(true);
      //       if (newAccessToken) {
      //         // Set the new token on the retried request
      //         error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
      //         // Also update Axios defaults for subsequent requests
      //         _axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;

      //         // Ensure Firebase’s user object is up to date
      //         await auth.updateCurrentUser(currentUser);

      //         // Update your local session store with the new token
      //         setUser(currentUser);

      //         console.log('User session refreshed');
      //         return resolve(_axiosInstance(error.config));
      //       } else {
      //         // No new token: remove from config & defaults
      //         console.log('Removing token in axios config');
      //         delete error.config.headers['Authorization'];
      //         if (_axiosInstance.defaults.headers.common['Authorization']) {
      //           delete _axiosInstance.defaults.headers.common['Authorization'];
      //         }
      //         throw new Error('Unable to refresh token');
      //       }
      //     } catch (refreshError) {
      //       // Final failure: remove the token, clear session, etc.
      //       clearUser();
      //       console.error('Error refreshing token', refreshError);

      //       delete error.config.headers['Authorization'];
      //       if (_axiosInstance.defaults.headers.common['Authorization']) {
      //         delete _axiosInstance.defaults.headers.common['Authorization'];
      //       }
      //       sessionStorage.clear();
      //       sessionStorage.setItem('redirectPath', window.location.pathname);
      //       return reject(refreshError);
      //     }
      //   });
      // }

      return Promise.reject(error);
    }
  );
};

export default setupAxiosInterceptors;
