import { useState, useEffect } from "react";
import { $axios } from "../services/axiosInstance";

import { useUser } from "../context/User";

export const mergeProjects = (existingProjects, newProjects) => {
  // Check if newProjects is an array, log a warning and return existingProjects if not
  if (!Array.isArray(newProjects)) {
    console.warn('newProjects must be an array. Function will return existing projects without changes.');
    return existingProjects; // Return existing projects to fail gracefully
  }

  const newProjectsMap = new Map(newProjects.map(project => [project.uuid, project]));

  // Filter existingProjects to include only those that exist in newProjectsMap
  const filteredExistingProjects = existingProjects.filter(project => newProjectsMap.has(project.uuid));

  // Merge filtered existing projects and new projects
  const updatedProjectMap = new Map();

  // Add the filtered existing projects to the map
  filteredExistingProjects.forEach(project => updatedProjectMap.set(project.uuid, project));

  // Add/Override with new projects
  newProjects.forEach(project => updatedProjectMap.set(project.uuid, project));

  return Array.from(updatedProjectMap.values());
};


const useProjectRefreshStatus = () => {
  const user = useUser();
  const [semrushAvailable, setSemrushAvailable] = useState(false);
  const [unitsAvailable, setUnitsAvailable] = useState(0);
  const [updatingProjects, setUpdatingProjects] = useState([]);
  const [updatedProjects, setUpdatedProjects] = useState([]);
  const [projectStatusError, setProjectStatusError] = useState(null);

  useEffect(() => {
    const fetchStatus = () => {
      if (!user || !user.subscribed) {
        return;
      }
      // Fetch call for processing projects 
      const processingProjects = $axios.get(`/company/project/get_recently_refreshed_projects/`)
        .then(response => ({ success: true, data: response.data }))
        .catch(error => ({ success: false, error }));

      // Fetch call for refreshed projects
      const refreshedProjects = $axios.get(`/company/project/refreshed_projects/`)
        .then(response => ({ success: true, data: response.data }))
        .catch(error => ({ success: false, error }));

      Promise.all([processingProjects, refreshedProjects])
        .then(([processingProjectsResponse, refreshedProjectsResponse]) => {
          // Procesing projects are merged with existing projects
          if (processingProjectsResponse.success) {
            setUpdatingProjects(prevProjects => mergeProjects(prevProjects, processingProjectsResponse.data.company_data));
            setSemrushAvailable(processingProjectsResponse.data.semrush_available);
            setUnitsAvailable(processingProjectsResponse.data.units_available);
          } else {
            setProjectStatusError(processingProjectsResponse.error);
            console.error('Error fetching processing projects', processingProjectsResponse.error);
          }

          // Refreshed projects are not merged with existing projects
          if (refreshedProjectsResponse.success) {
            setUpdatedProjects(refreshedProjectsResponse.data);
          } else {
            setProjectStatusError(refreshedProjectsResponse.error);
            console.error('Error fetching refreshed projects', refreshedProjectsResponse.error);
          }
        })
    };

    // Fetch status immediately when the component mounts
    fetchStatus();

    // Set up the interval for polling
    const intervalId = setInterval(fetchStatus, 30000); // Polling every 30 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return { updatingProjects, setUpdatingProjects, updatedProjects, projectStatusError, semrushAvailable, unitsAvailable };
};

export default useProjectRefreshStatus;
