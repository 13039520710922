import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useAuth0 } from "@auth0/auth0-react";

import { useUser } from "../../context/User";

function Paywall({ children }) {
  const { user: auth0User, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const user = useUser();

  const handleSignup = (e) => {
    if (!isAuthenticated) {
      e.preventDefault()
      return loginWithRedirect({
        appState: {
          returnTo: `/subscription/checkout`,
        },
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Upgrade Your Access | Genti</title>
      </Helmet>
      <div>
        <div className="text-center">
          { children }
        </div>
        <div className="flex flex-col justify-center gap-12 px-3 py-3 md:min-h-[30rem] md:flex-row md:gap-0 md:py-12">
          <div className="text-sm relative flex-1 flex gap-5 flex-col border-t border-l border-r border-b rounded-xl border-deep-space dark:border-white md:min-h-[30rem] md:rounded-none md:border-r-0 md:last:border-r md:first:rounded-tl-xl md:first:rounded-bl-xl md:last:rounded-tr-xl md:last:rounded-br-xl md:max-w-96 justify-center px-6 pt-6 pb-10 md:pb-6">
            <div className="relative flex flex-col bg-token-main-surface-primary">
              <div className="flex flex-col gap-1">
                <p className="flex items-center gap-2 text-2xl font-semibold">Free</p>
                <div className="ml-4 mt-2 flex items-baseline gap-1.5">
                  <div className="relative">
                    <p className="absolute -left-4 -top-0 text-2xl text-token-text-secondary">$</p>
                    <div className="flex items-baseline gap-1.5">
                      <div className="text-5xl text-token-text-primary">
                        <div className="text-5xl text-token-text-primary">0</div>
                      </div>
                      <div className="flex flex-col items-start justify-center">
                        <p className="absolute mb-6 text-xs text-token-text-tertiary">USD/</p>
                        <p className="text-xs text-token-text-tertiary">month</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mr-2 mt-2 text-base text-token-text-primary">Explore how AI can help you with everyday tasks</p>
              </div>
            </div>
            <div className="relative flex flex-col">
              { user.subscribed === true || !auth0User
              ? <Link to={!auth0User ? "/subscription/checkout" : "/subscription/update"} className="bg-deep-space bg-opacity-20 hover:bg-opacity-30 dark:bg-opacity-0 hover:dark:bg-opacity-20 btn relative border border-deep-space dark:border-white font-semibold text-deep-space dark:text-white">
                  Get FREE
              </Link>
              : <button disabled={true} className="bg-deep-space bg-opacity-20 hover:bg-opacity-30 dark:bg-white dark:bg-opacity-20 hover:dark:bg-opacity-30 cursor-not-allowed btn relative border-none font-semibold text-deep-space dark:text-white">
                  Your Current Plan
                </button>
              }
            </div>
            <div className="flex flex-col flex-grow gap-2">
              <div className="relative bg-token-main-surface-primary">
                <div className="text-l flex justify-start gap-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-0.5 h-4 w-4 shrink-0">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path>
                  </svg>
                  <span>Access to GPT-4o mini and reasoning</span>
                </div>
              </div>
            </div>
            <div>
              <div className="relative flex flex-col text-xs text-token-text-secondary bg-token-main-surface-primary">
                <div>Have an existing plan? See <a className="underline" href="#" target="_blank">billing help</a></div>
              </div>
            </div>
          </div>
          {/* FREE */}

          <div className="text-sm relative flex-1 flex gap-5 flex-col border-t border-l border-r border-b rounded-xl md:first:rounded-tl-xl md:first:rounded-bl-xl md:last:rounded-tr-xl md:last:rounded-br-xl md:max-w-96 justify-center px-6 pt-6 pb-10 md:pb-6 md:min-h-[20rem] -mt-4 -mb-4 border-[#0561B7] bg-[#0561B7] bg-opacity-5 dark:border-watermelon-500 dark:bg-watermelon-500 dark:bg-opacity-5">
            <div className="relative flex flex-col mt-4">
              <div className="flex flex-col gap-1">
                <p className="flex items-center gap-2 text-2xl font-semibold">Plus<span className="ml-1 rounded border px-1 py-0 text-xs font-semibold border-[#0561B7] text-[#0561B7] dark:border-watermelon-500 dark:text-watermelon-500">POPULAR</span></p>
                <div className="ml-4 mt-2 flex items-baseline gap-1.5">
                  <div className="relative">
                    <p className="absolute -left-4 -top-0 text-2xl text-token-text-secondary">$</p>
                    <div className="flex items-baseline gap-1.5">
                      <div className="text-5xl text-token-text-primary">
                        <div className="text-5xl text-token-text-primary">99.99</div>
                      </div>
                      <div className="flex flex-col items-start justify-center">
                        <p className="absolute mb-6 text-xs text-token-text-tertiary">USD/</p>
                        <p className="text-xs text-token-text-tertiary">month</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mr-2 mt-2 text-base text-token-text-primary">Level up productivity and creativity with expanded access</p>
              </div>
            </div>
            <div className="relative flex flex-col bg-token-main-surface-primary">
              <div>
                <div className="">
                  { user?.subscribed === true 
                  ? <button disabled={true} className="btn w-full bg-[#0561B7] bg-opacity-10 dark:bg-watermelon-500 dark:bg-opacity-10 hover:bg-opacity-20 hover:dark:bg-opacity-30 border border-[#0561B7] dark:border-watermelon-500 relative font-semibold">
                      Your Current Plan
                    </button> 
                  : <Link to="/subscription/checkout" onClick={handleSignup} className="w-full block text-center hover:no-underline p-4 bg-[#0561B7] bg-opacity-10 dark:bg-watermelon-500 dark:bg-opacity-10 hover:bg-opacity-20 hover:dark:bg-opacity-30 border border-[#0561B7] dark:border-watermelon-500 relative font-semibold">
                      <span className='text-deep-space dark:text-white'>Get Plus</span>
                    </Link>
                  }
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-grow gap-2">
              <div className="relative">
                <div className="text-l flex justify-start gap-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-0.5 h-4 w-4 shrink-0">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path>
                  </svg>
                  <span>Everything in Free</span>
                </div>
              </div>
              <div className="relative">
                <div className="text-l flex justify-start gap-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-0.5 h-4 w-4 shrink-0">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path>
                  </svg>
                  <span>Extended limits on messaging, file uploads, advanced data analysis, and image generation</span>
                </div>
              </div>
              <div className="relative">
                <div className="text-l flex justify-start gap-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-0.5 h-4 w-4 shrink-0">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path>
                  </svg>
                  <span>Standard and advanced voice mode</span>
                </div>
              </div>
            </div>
            <div>
              <div className="relative flex flex-col text-xs text-token-text-secondary">
                <div><a className="px-2 underline" href="#" target="_blank">Limits apply</a></div>
              </div>
            </div>
          </div>
          {/* PLUS */}

          <div className="text-sm relative flex-1 flex gap-5 flex-col border-t border-l border-r border-b rounded-xl border-deep-space dark:border-white md:min-h-[30rem] md:rounded-none md:border-l-0 md:last:border-r md:first:rounded-tl-xl md:first:rounded-bl-xl md:last:rounded-tr-xl md:last:rounded-br-xl md:max-w-96 justify-center px-6 pt-6 pb-10 md:pb-6">
            <div className="relative flex flex-col bg-token-main-surface-primary">
              <div className="flex flex-col gap-1">
                <p className="flex items-center gap-2 text-2xl font-semibold">Enterprise</p>
                <div className="ml-4 mt-2 flex items-baseline gap-1.5">
                  <div className="relative">
                    <p className="absolute -left-4 -top-0 text-2xl text-token-text-secondary">$</p>
                    <div className="flex items-baseline gap-1.5">
                      <div className="text-5xl text-token-text-primary">
                        <div className="text-5xl text-token-text-primary">999.99</div>
                      </div>
                      <div className="flex flex-col items-start justify-center">
                        <p className="absolute mb-6 text-xs text-token-text-tertiary">USD/</p>
                        <p className="text-xs text-token-text-tertiary">month</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mr-2 mt-2 text-base text-token-text-primary">Enjoy full, uncapped access to all of our platform's features</p>
              </div>
            </div>
            <div className="relative flex flex-col">
              <Link to="/subscription/update" className="bg-deep-space bg-opacity-20 hover:bg-opacity-30 dark:bg-opacity-0 hover:dark:bg-opacity-20 btn relative border border-deep-space dark:border-white font-semibold text-deep-space dark:text-white">
                  Contact Us
              </Link>
            </div>
            <div className="flex flex-col flex-grow gap-2">
              <div className="relative bg-token-main-surface-primary">
                <div className="text-l flex justify-start gap-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-0.5 h-4 w-4 shrink-0">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path>
                  </svg>
                  <span>Everything in Free</span>
                </div>
              </div>
              <div className="relative bg-token-main-surface-primary">
                <div className="text-l flex justify-start gap-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-0.5 h-4 w-4 shrink-0">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path>
                  </svg>
                  <span>Everything in Plus</span>
                </div>
              </div>
              <div className="relative bg-token-main-surface-primary">
                <div className="text-l flex justify-start gap-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-0.5 h-4 w-4 shrink-0">
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0633 5.67387C18.5196 5.98499 18.6374 6.60712 18.3262 7.06343L10.8262 18.0634C10.6585 18.3095 10.3898 18.4679 10.0934 18.4957C9.79688 18.5235 9.50345 18.4178 9.29289 18.2072L4.79289 13.7072C4.40237 13.3167 4.40237 12.6835 4.79289 12.293C5.18342 11.9025 5.81658 11.9025 6.20711 12.293L9.85368 15.9396L16.6738 5.93676C16.9849 5.48045 17.607 5.36275 18.0633 5.67387Z" fill="currentColor"></path>
                  </svg>
                  <span>Unlimited refreshes</span>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
          {/* Enterprise */}
        </div>
      </div>
    </>
  );
}

export default Paywall;
