import { useState, useEffect } from "react";
import { $axios } from "../../../services/axiosInstance";

import { useUser } from "../../../context/User";
import { useProfile } from "../../../context/Profile";

const useCluster = (clusterId, profileId) => {
  const user = useUser();
  const { is_internal } = useProfile();
  const [cluster, setCluster] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the cluster data once
  useEffect(() => {
    const fetchCluster = async () => {
      if (!user?.subscribed && !is_internal) {
        setLoading(false);
        return;
      }

      try {
        const { data } = await $axios.get(`/cluster-profile/${clusterId}/${profileId}/`);
        setCluster(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCluster();
  }, [clusterId, profileId]);

  // If the fetched cluster does not have an image,
  // poll every 2 seconds until the image becomes available.
  useEffect(() => {
    let pollInterval;
    if (cluster && !cluster.cluster_image) {
      pollInterval = setInterval(async () => {
        try {
          const { data: imageData } = await $axios.get(
            `/cluster-profile/${clusterId}/${profileId}/image/`
          );

          // Assume the endpoint returns an object with an "image" property
          if (imageData && imageData.cluster_image) {
            setCluster(prev => ({ ...prev, cluster_image: imageData.cluster_image }));
            clearInterval(pollInterval);
          }
        } catch (err) {
          console.error("Error polling for cluster image:", err);
        }
      }, 2000);
    }
    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, [cluster, clusterId]);

  return { cluster, loading, error };
};

export default useCluster;
