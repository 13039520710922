import { createContext, useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useAuth0, Auth0Provider } from '@auth0/auth0-react';

import { $axios } from "../services/axiosInstance";

export const UserContext = createContext()

export default function Provider({children}) {
  const [context, setContext] = useState({isLoading: true, subscribed: false})
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  const getSubscription = useCallback(
    async () => {
      const { data } = await $axios.get("/public/subscription")
      const { subscribed } = data
      setContext({
        isLoading: false,
        subscribed,
      })

      return {
        subscribed,
      }
    }, []
  )

  useEffect(() => {
    if (isAuthLoading) {
      setContext({isLoading: true, subscribed: false})
      return
    }
    if (!isAuthenticated) {
      setContext({isLoading: false, subscribed: false})
      return
    }

    $axios.get("/public/subscription")
      .then(({ data }) => setContext({ ...data, isLoading: false }))
      .catch(() => setContext({ isLoading: false })); // Handle API errors gracefully

  }, [isAuthLoading, isAuthenticated])

  const { subscribed, isLoading } = context
  const contextValue = useMemo(() => ({
    subscribed,
    isLoading,
    getSubscription,
  }), [subscribed, isLoading, getSubscription])

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
