// react
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// hooks
import useCompanyPolygon from "../../hooks/useCompanyPolygon";
import useProjectRefreshStatus from "../../hooks/useProjectRefreshStatus";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from '@awesome.me/kit-d96c79f0ca/icons/classic/solid'

// axios
import { $axios } from "../../services/axiosInstance";

// components
import Paywall from "../../components/Paywall/Paywall";
import Loading from "../../components/Loading/Loading";
import CompanyChart from "./CompanyChart";
import NewsFeed from "./NewsFeed";
import ProjectList from "../../components/ProjectList/ProjectList";

import { useUser } from "../../context/User";
import { useProfile } from "../../context/Profile";

// styles
import "./Company.css";

const Company = () => {
  const user = useUser();
  const { isLoading: isLoadingProfile, is_internal } = useProfile();
  const { updatingProjects, updatedProjects, projectStatusError } = useProjectRefreshStatus();

  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const uuid = useParams().companyId;
  const [polygonData, setPolygonData] = useCompanyPolygon(company?.ticker);
  const [selectedDifficulties, setSelectedDifficulties] = useState({
    lowest: true,
    low: true,
    moderate: true,
    high: true,
    highest: true,
  });

  useEffect(() => {
    setLoading(true);

    $axios
      .get(`/company/company/${uuid}/`)
      .then((response) => {
        setCompany(response.data);

        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          setError({
            message: "Company not found",
            status: 404,
          });
        } else {
          setError({
            message: "Error fetching company",
            status: error.response?.status,
          });
        }

        setLoading(false);
      });
  }, [uuid]);

  if (loading || isLoadingProfile) {
    return <Loading />;
  }

  if (!user || Object.keys(user).length === 0 || (user?.subscribed !== true && (is_internal ?? false) === false)) {
    return (
      <Paywall>
        <h1 className="text-2xl">
          Upgrade your plan to view this company
        </h1>
        <p className="text-center">
          Your current free tier allows limited access. To view this company, please upgrade your subscription.
        </p>
      </Paywall>
    );
  }

  if (error) {
    return (<>
      <Helmet>
        <title>{`Genti - Company`}</title>
      </Helmet>
      <div className="company-page page-base">
        <section className="info">
          <div className="page-header">
            <h1>{error.message}</h1>
          </div>

          <p className="py-40 text-center">
            Try going back to the{" "}
            <Link
              className="bold text-watermelon-500 hover:underline"
              to="/leaderboard"
            >
              Leaderboard
            </Link>{" "}
            page.
          </p>
        </section>
      </div>
    </>);
  }

  return <>
    <Helmet>
      <title>{`Genti - ${company.name || ""}`}</title>
    </Helmet>
    
    <div className="company-page page-base">
      <section className="page-section">
          <div className="section-header flex items-center gap-2">
            <h1>{company.name}</h1>
            { company.company_url && <Link to={company.company_url}>
              <FontAwesomeIcon icon={faLink} size="sm" />
            </Link>}
          </div>


        <div className="p-4">
          { loading 
            ? null 
            : polygonData && polygonData.description 
            ? <p>{polygonData.description}</p>
            : <p>No description available</p>
          }
        </div>

        <hr className="border-[#0561B7] border-opacity-50 border-dotted mx-4" />
        
        <CompanyChart company={company} marketCap={polygonData?.market_cap} />
      </section>

      <section className="page-section">
        {/* <div className="section-header flex justify-between">
          <h2>Projects</h2>

          <ProjectFilter
            selectedDifficulties={selectedDifficulties}
            handleToggle={handleToggle}
          />
        </div> */}

          <ProjectList
            company={company}
            projects={company.brand_project}
            selectedDifficulties={selectedDifficulties}
            projectStatus={updatingProjects.filter(
              (s) => s.parent_company === company.uuid
            )}
            withHeader={true}
          />

      </section>

      <section className="page-section">
        <h2 className="section-header">Recent News</h2>

        <NewsFeed ticker={company.ticker} />
      </section>
    </div>
  </>;
};

export default Company;
