import { Suspense, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, Lightformer } from '@react-three/drei';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../utils/ThemeManager';

import logo from '../../assets/img/sunrise.svg';
import logoOrange from '../../assets/img/sunrise-deep-space.svg';
import Sunrise from '../../canvas/Sunrise.jsx';

import { useAuth0 } from "@auth0/auth0-react";

import './SignIn.css';

const SignIn = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { user, error, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // If the user is already authenticated, redirect them to the leaderboard page
    if (isAuthenticated) {
      navigate('/leaderboard')
      return;
    }
  }, [isAuthenticated])

  return (
    <>
      <Helmet>
        <title>Welcome | Genti</title>
      </Helmet>

      <div className="signin-page">
        <Canvas
          orthographic
          camera={{ position: [-1, -0.5, 5], zoom: 5000 }}
          style={{ zIndex: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        >
          <ambientLight />
          <directionalLight intensity={1} position={[0, 0, 10]} />
          <Suspense fallback={null}>
            <Sunrise scale={0.1} position-y={-0.02} />
          </Suspense>
          <Environment resolution={256}>
            <group rotation={[-Math.PI / 2, 0, 0]}>
              <Lightformer intensity={4} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
              {[2, 0, 2, 0, 2, 0, 2, 0].map((x, i) => (
                <Lightformer
                  key={i}
                  form="circle"
                  intensity={10}
                  rotation={[Math.PI / 2, 0, 0]}
                  position={[x, 4, i * 4]}
                  scale={[4, 1, 1]}
                />
              ))}
              <Lightformer intensity={4} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[50, 2, 1]} />
              <Lightformer intensity={4} rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[50, 2, 1]} />
              <Lightformer intensity={4} rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[50, 2, 1]} />
            </group>
          </Environment>
        </Canvas>

        <div className="overlay">
          <img className="w-10" src={theme === 'dark' ? logo : logoOrange} alt="" />
          <div>
            <h1>GenTi</h1>
            <p>Be the Answer</p>
            { user && (
              <div>
                <p>Welcome back, {user.name}!</p>
                <p>{user.email}</p>
              </div>
            )}

            { error && <p>{error.message}</p>}
          </div>
        </div>

        {!isLoading && <div className="sign-in">
          <div className="btn-container">
            <button className="btn" onClick={() => loginWithRedirect()}>
              <svg fill="currentColor" height="36" viewBox="0 0 53 64" className="sc-DhHLR cUiFug base-QuantumLogo-svg"><path d="M3.372 27.782c10.475-1.728 18.686-10.4 20.407-20.892l.577-5.043c.143-.802-.4-1.918-1.412-1.838C15.03.628 7.56 3.243 3.406 4.943A5.488 5.488 0 000 10.027v16.451c0 .973.874 1.717 1.834 1.563l1.538-.252v-.006zM28.831 6.89c1.726 10.492 9.938 19.164 20.407 20.893l1.537.252c.96.16 1.835-.584 1.835-1.563v-16.45a5.488 5.488 0 00-3.406-5.084C45.044 3.232 37.58.622 29.666.004c-1.018-.08-1.538 1.048-1.418 1.837l.577 5.043.006.006zM49.23 32.39C34.915 35.219 28.27 44.75 28.27 62.79c0 .905.897 1.535 1.651 1.031 6.584-4.447 21.07-16.056 22.562-30.577.057-1.826-2.223-.968-3.252-.853zM3.377 32.39c14.315 2.828 20.961 12.359 20.961 30.4 0 .905-.897 1.535-1.651 1.031C16.103 59.374 1.617 47.765.125 33.244c-.057-1.826 2.223-.968 3.252-.853z"></path></svg>
              <div className="btn-border tl" role="presentation" />
              <div className="btn-border tr" role="presentation" />
              <div className="btn-border br" role="presentation" />
              <div className="btn-border bl" role="presentation" />
              <span className="sr-only">Sign In With Auth0</span>
            </button>
          </div>
        </div>}
      </div>
    </>
  );
};

export default SignIn;
