import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import useCluster from "./hooks/useCluster";
import { useUser } from "../../context/User";
import { useProfile } from "../../context/Profile";

import Loading from "../../components/Loading/Loading";
import Paywall from "../../components/Paywall/Paywall";
import ProjectInfoHeader from "../../components/ProjectInfoHeader/ProjectInfoHeader";
import ChartSlider from "../../components/ChartSlider/ChartSlider";
import Slider from "../../components/Slider/Slider";
import SearchVolMonth from "./Visualizations/SearchVolMonth";
import SearchVolBubble from "./Visualizations/SearchVolBubble";
import PaidSERP from "./Visualizations/PaidSERP";
import ClusterSERPFeatures from "./Visualizations/ClusterSERPFeatures";
import ClusterTable from "./Visualizations/ClusterTable";
import ClusterLightbox from "./ClusterLightbox/ClusterLightbox";

import QuestionCard, { groupQuestions } from "./QuestionCard/QuestionCard";

import {
  formatCurrency,
  formatFloat,
  formatPercent,
} from "../../utils/tableUtils";

import "./Cluster.css";

import fallback from "../../assets/img/fallback.webp";

function Cluster() {
  const user = useUser();
  const { isLoading: isLoadingProfile, is_internal, is_internal_admin } = useProfile();
  const location = useLocation();
  const history = location.state?.history || [];
  const { clusterId, profileId } = useParams();
  const { cluster, loading, error } = useCluster(clusterId, profileId);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  // set element with class App to overflow hidden when lightbox is open
  useEffect(() => {
    const appElement = document.querySelector(".App");
    const htmlElement = document.querySelector("html");
  
    if (appElement && htmlElement) {
      if (lightboxOpen) {
        appElement.style.overflow = "hidden";
        htmlElement.style.overflow = "hidden";
      } else {
        appElement.style.overflow = "auto";
        htmlElement.style.overflow = "auto";
      }
    }
  }, [lightboxOpen]);

  if (loading || isLoadingProfile) {
    return <Loading />;
  }

  if (!user || Object.keys(user).length === 0 || (user?.subscribed !== true && (is_internal ?? false) === false)) {
    return (
      <div className="page-base">
        <Paywall>
          <h1 className="text-2xl">
            Upgrade your plan to view this cluster
          </h1>
          <p className="text-center">
            Your current free tier allows limited access. To view this cluster, please upgrade your subscription.
          </p>
        </Paywall>
      </div>
    );
  }

  if (error) {
    return (<>
    <Helmet>
      <title>{`Genti - Idea`}</title>
      <meta property="og:description" content="A new horizon. Empowering self-care." />
    </Helmet>

      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">Error fetching data</h1>
            <p className="text-red-500 font-mono">
              {error?.message || "Something went wrong."}
            </p>
          </div>
        </section>
      </div>
    </>);
  }

  if (cluster == null || Object.keys(cluster).length === 0) {
    return (<>
      <Helmet>
        <title>{`Genti - Idea`}</title>
        <meta property="og:description" content="A new horizon. Empowering self-care." />
      </Helmet>

      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">No data available</h1>
            <p className="font-mono">
              No cluster data available for this profile.
            </p>
          </div>
        </section>
      </div>
    </>);
  }

  const groupedQuestions = groupQuestions(cluster.cluster_data?.popular_questions, 2);

  const shareImage = cluster?.cluster_image || fallback; // Use the hosted fallback image if cluster_image is not available

  return (
    <>
      <Helmet>
        <title>{cluster?.cluster_data?.idea?.title || "Idea"} | Genti</title>
        <meta property="og:image" content={shareImage} />
        <meta property="og:description" content={cluster?.cluster_data?.summary  || ""} />
        <meta property="og:url" content={`https://genti.ai/cluster/${clusterId}/${profileId}`} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={cluster?.cluster_data?.idea?.title || "Idea"} />
        <meta name="twitter:description" content={cluster?.cluster_data?.summary || ""} />
        <meta property="twitter:image" content={shareImage} />
      </Helmet>

      <div className="cluster page-base space-y-8 md:space-y-12">

        {/* cluster info */}
        <div className="space-y-2">
          { cluster?.brand_project && (
            <ProjectInfoHeader project={cluster.brand_project} linkToProjectPage={true} />
          )}

          { cluster?.condition && (
            <h1 className="text-deep-space dark:text-[#C0D7Ed] text-2xl font-bold">
              <Link to={`/clusters/${cluster.condition.uuid}`}>
                {cluster.condition.name}
              </Link>
            </h1>
          )}

          <section className="page-section cluster-info">
            <div className="section-header h-full flex flex-col col-span-full md:row-span-1">
              <h2 className="">{ cluster?.cluster_data?.title }</h2>
            </div>
            <img
              src={cluster?.cluster_image || fallback}
              alt=""
              className="cluster-img md:col-span-1 lg:row-span-2"
              onClick={() => setLightboxOpen(true)}
            />
  
            <div className="h-full col-span-full lg:row-start-2 grid grid-cols-2 grid-rows-2 lg:grid-cols-4 lg:grid-rows-1 p-4 gap-4">
              <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
                <h2 className="section-header text-base text-center p-2 font-bold">Category</h2>
                <Link
                  to={`/clusters/${clusterId}?category=${encodeURIComponent(cluster?.cluster_data?.primary_category || '')}`}
                  className="p-2 flex-1 text-xl text-center font-bold content-center"
                >
                  {cluster?.cluster_data?.primary_category}
                </Link>
              </div>
              <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
                <h2 className="section-header text-base text-center p-2 font-bold">Reach</h2>
                <p className="p-2 flex-1 text-2xl text-center font-bold content-center">{ formatFloat(cluster?.cluster_data?.annual_search_volume, 0)}</p>
              </div>
              <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
                <h2 className="section-header text-base text-center p-2 font-bold">Difficulty</h2>
                <p className="p-2 flex-1 text-2xl text-center font-bold content-center">{ formatPercent(cluster?.cluster_data?.avg_diff, 0) }</p>
              </div>
              <div className="flex flex-col border border-[#0561B7] border-opacity-50 rounded">
                <h2 className="section-header text-base text-center p-2 font-bold">Opportunity</h2>
                <p className="p-2 flex-1 text-2xl text-center font-bold content-center">{ formatFloat(cluster?.cluster_data?.avg_opp, 0) }</p>
              </div>
            </div>

            <div className="px-4 md:px-6 pb-4 row-span-2 h-full">
              <ul className="h-full w-full space-y-4 flex flex-col justify-around">
                { cluster?.cluster_data?.search_motivations?.slice(0, 3).map((reason, index) => (
                  <li key={index} className="">
                    <h3 className="font-bold text-xl text-deep-space dark:text-[#C0D7ED]">{reason.title}</h3>
                    <p className="ml-2 px-2 text-lg border-l border-watermelon-500">{reason.description}</p>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>

        {/* most popular questions */}
        <section className="">


          {
            cluster.cluster_data?.popular_questions && (
              <div className="flex flex-col gap-4 md:gap-8">
                { cluster.cluster_data?.popular_questions?.length > 0 
                  ? cluster.cluster_data?.popular_questions.map((question, index) => (
                    <QuestionCard
                      question={question}
                      index={index}
                      key={index}
                    />
                  ))
                  : <p>No popular questions available for this cluster.</p>
              }
              </div>
            )
          }

          {/* <SearchVolMonth id="questions" data={cluster.cluster_data?.total_search_volume_by_month} /> */}
        </section>

        {/* keywords */}
        { (is_internal || is_internal_admin) && <section className="page-section">
          <div>
            <h2 className="section-header">Keywords</h2>

            <ChartSlider>
              <SearchVolMonth id="keywords" data={cluster.cluster_data?.total_search_volume_by_month} />
              <SearchVolBubble data={cluster.cluster_data?.visualizations?.search_volume_by_type} />
              <PaidSERP data={cluster.cluster_data?.visualizations?.paid_serp} />
              <ClusterSERPFeatures data={cluster.cluster_data?.visualizations?.serp_features} />
            </ChartSlider>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="info-boxes grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">Share</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">  { formatPercent(cluster?.cluster_data?.visualizations?.cluster_share?.[1]?.y ?? 0, 2) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_keyword_count, 0) }/kws</div>
                </div>
              </div>
            </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                  <h2 className="font-bold text-center">
                    Total Volume (mo/yr)
                  </h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_search_volume, 0) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.annual_search_volume, 0) }</div>
                  </div>
                </div>
            </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">Difficulty (avg/high)</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.avg_diff, 0) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.most_difficult_diff?.max_kwd_value, 0) }</div>
                </div>
              </div>
            </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">CPC (avg/high)</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.avg_cpc) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.most_expensive_cpc?.max_cpc_value, 2) }</div>
                </div>
              </div>
            </div>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="overflow-x-auto">
            <ClusterTable
              data={cluster.cluster_data?.visualizations?.keywords}
              profileId={profileId}
              history={history}
            />
          </div>
        </section>}
      </div>

      { lightboxOpen && (
        <ClusterLightbox
          cluster={cluster}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </>
  );
}

export default Cluster;

