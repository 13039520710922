import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  useNavigate,
} from "react-router-dom";

import ACNUDashboard from "./pages/ACNUDashboard/ACNUDashboard";
import ACNUFeasibility from "./pages/ACNUFeasibility/ACNUFeasibility";
import Admin from "./pages/Admin/Admin";
import Callback from "./pages/Callback/Callback";
import Cluster from "./pages/Cluster/Cluster";
import Clusters from "./pages/Clusters/Clusters";
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import Company from "./pages/Company/Company";
import CompanyAdd from "./pages/CompanyAdd/CompanyAdd";
import CompanyEdit from "./pages/CompanyEdit/CompanyEdit";
import CompetitiveAnalysis from "./pages/CompetitiveAnalysis/CompetitiveAnalysis";
import ConditionAnalysis from "./pages/ConditionAnalysis/ConditionAnalysis";
import ConditionEdit from "./pages/Admin/ConditionEdit/ConditionEdit";
import Header from "./components/Header/Header";
import KeywordCategories from "./pages/Admin/KeywordCategories/KeywordCategories";
import Missing from "./pages/Missing/Missing";
import KeywordUniverse from "./pages/KeywordUniverse/KeywordUniverse";
import Loading from "./components/Loading/Loading";
import Prices from "./pages/Prices/Prices";
import ProductivityBooster from "./pages/ProductivityBooster/ProductivityBooster";
import Profile from "./pages/Profile/Profile";
import Project from "./pages/Project/Project";
import ProjectEdit from "./pages/ProjectEdit/ProjectEdit";
import ProjectStatus from "./pages/ProjectStatus/ProjectStatus";
import ProtectedRoute from "./utils/ProtectedRoute";
import SignIn from "./pages/SignIn/SignIn";
import SubscriberWelcome from "./pages/SubscriberWelcome/SubscriberWelcome";
import { Subscription, SubscriptionForm, UpdateForm } from "./pages/Subscriptions";
import { useUser } from "./context/User";

// admin pages
import ConditionLibrary from "./pages/Admin/ConditionLibrary/ConditionLibrary";
import ConditionProfile from "./pages/Admin/ConditionProfile/ConditionProfile";
import KeywordUniverseAdmin from "./pages/Admin/KeywordUniverse/KeywordUniverse";
import KeywordProfile from "./pages/Admin/KeywordProfile/KeywordProfile";

import AxiosInterceptorSetup from "./auth/AxiosInterceptorSetup";
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";
import UserProvider from "./context/User"
import ProfileProvider from "./context/Profile"
import ConfigProvider from "./context/Config"

import { $axios } from "./services/axiosInstance";

import { ToastContainer } from 'react-toastify';
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "./utils/ThemeManager";
import { useProfile } from "./context/Profile";

const AdminContent = () => {
  const { is_internal_admin, isLoading: isLoadingProfile } = useProfile();
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect if user is not logged in
    if (!isLoading && !user) {
      navigate('/leaderboard', { replace: true });
    }

    // Redirect if user is logged in but not an admin
    if (!isLoadingProfile && is_internal_admin === false) {
      navigate('/leaderboard', { replace: true });
    }
  }, [user, isLoading, is_internal_admin, isLoadingProfile, navigate]);

  if (isLoading || isLoadingProfile) {
    return <Loading />;
  }

  if (!is_internal_admin) {
    return null; // briefly shows nothing while redirecting
  }

  return <Outlet />;
};

const AppLayout = () => {
  const { theme } = useTheme();

  return (
    <>
      <AxiosInterceptorSetup />
      <Header />
      <main className="container">
        <Outlet />
        <ToastContainer 
          position="bottom-right"
          theme={theme}
        />
      </main>
    </>
  );
};

const Root = () => (
  <ConfigProvider>
    <Auth0ProviderWithHistory>
      <UserProvider>
        <ProfileProvider>
          <AppLayout />
        </ProfileProvider>
      </UserProvider>
    </Auth0ProviderWithHistory>
  </ConfigProvider>
);

function Loader() {
  const { isLoading } = useAuth0()
  const { isLoading: isUserLoading } = useUser()

  if (isLoading || isUserLoading) {
    return <Loading />
  }

  return <Outlet/>
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      {/* protected routes */}
      <Route element={<ProtectedRoute />}>
          <Route element={<AdminContent />}>
            <Route path="/admin" element={<Admin />} exact />
            <Route path="/admin/keyword-categories" element={<KeywordCategories />} />
            <Route path="/admin/condition-library" element={<ConditionLibrary />} />
            <Route path="/admin/condition-edit/:conditionId" element={<ConditionEdit />} />
            <Route path="/admin/condition-profile/:conditionId" element={<ConditionProfile />} />
            <Route path="/admin/keyword-universe" element={<KeywordUniverseAdmin />} />
            <Route path="/admin/keyword-profile/:keywordId" element={<KeywordProfile />} />
          </Route>

          <Route path="/project-edit/:projectId" element={<ProjectEdit />} exact />
          <Route path="/project-status" element={<ProjectStatus />} exact />

          <Route path="/company-add" element={<CompanyAdd />} exact />
          <Route path="/company-edit/:companyId" element={<CompanyEdit />} exact />

          <Route path="/project/:projectId/keyword-universe" element={<KeywordUniverse />} exact />
          <Route path="/project/:projectId/acnu-feasibility" element={<ACNUFeasibility />} exact />
          <Route path="/project/:projectId/acnu_dashboard" element={<ACNUDashboard />} exact />
          <Route path="/project/:projectId/competitive-analysis" element={<CompetitiveAnalysis />} exact />
          <Route path="/project/:projectId/condition-analysis" element={<ConditionAnalysis />} exact />
          <Route path="/subscription" element={<Subscription/>}/>
          <Route path="/subscription/update" element={<UpdateForm/>}/>
      </Route>

      <Route element={<Loader/>}>
        <Route path="/company/:companyId" element={<Company />} exact />
        <Route path="/project/:projectId/" element={<Project />} exact />
        <Route path="/clusters/:clusterId" element={<Clusters />} exact />
        <Route path="/cluster/:clusterId/:profileId" element={<Cluster />} exact />
      </Route>

      {/* public routes */}
      <Route path="/callback" element={<Callback />} exact />
      <Route path="/leaderboard" element={<Leaderboard />} exact />
      <Route path="/prices" element={<Prices/>}/>
      <Route path="/profile" element={<Profile />} exact />
      <Route path="/productivity-booster" element={<ProductivityBooster />} exact />
      <Route path="/subscription/checkout" element={<SubscriptionForm/>}/>
      <Route path="/welcome" element={<SubscriberWelcome/>}/>
      <Route path="/" element={<SignIn />} exact />

      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Route>
  )
);
