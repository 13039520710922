import { useEffect, useState } from "react";
import { useUser } from "../../context/User.js";
import { useProfile } from "../../context/Profile.js";

function RoleBadge() {
  const { isLoading: isLoadingUser, subscribed } = useUser();
  const { isLoading: isLoadingProfile, is_internal, is_internal_admin, subscription_info } = useProfile();

  const [role, setRole] = useState(null);

  useEffect(() => {
    if (isLoadingUser || isLoadingProfile) return;

    if (is_internal_admin) {
      setRole("admin");
    } else if (is_internal) {
      setRole("internal");
    } else if (subscription_info?.subscribed) {
      setRole("pro");
    }
  }, [is_internal_admin, is_internal, isLoadingUser, isLoadingProfile, subscription_info]);

  if (!role) return null;

  return (
    <span className="lg:translate-x-4 text-[10px] bg-[#0561B7] text-white dark:bg-watermelon-500 rounded-full px-2 shadow-sm leading-[1.5] md:leading-normal">
      {role.charAt(0).toUpperCase() + role.slice(1)}
    </span>
  );
}

export default RoleBadge;
