// react
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useEffect, useState, useReducer } from "react";
import { Helmet } from "react-helmet-async";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

// axios
import { $axios } from "../../services/axiosInstance";

// styles
import "./CompanyEdit.css";

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPANY_DATA":
      return {
        ...state,
        name: action.companyData.name ? action.companyData.name : "",
        ticker: action.companyData.ticker ? action.companyData.ticker : "",
      };
    case "UPDATE_FIELD":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

const CompanyEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { company } = location.state || {};
  const [error, setError] = useState(null);

  const initialFormState = {
    name: "",
    ticker: "",
  };

  const [companyForm, dispatch] = useReducer(formReducer, initialFormState);

  useEffect(() => {
    if (company && company.uuid) {
      dispatch({ type: "SET_COMPANY_DATA", companyData: company });
    } else {
      navigate("/leaderboard");
    }
  }, []);

  const handleInputChange = (e) => {
    setError(null);
    dispatch({
      type: "UPDATE_FIELD",
      field: e.target.name,
      value: e.target.value,
    });
  };

  // function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!companyForm.name || !companyForm.ticker) {
      setError("Please fill in all fields");
      return;
    }

    const updatedCompany = {
      ...company,
      ...companyForm,
    };

    try {
      await $axios.put(`/company/${company.uuid}/`, updatedCompany);
      navigate("/leaderboard");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Edit {companyForm.name || "Company"} | Genti</title>
      </Helmet>
      
      <section className="company-add-edit">
        <div className="page-header">
          <Link to="/leaderboard" className="">
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              size="xl"
              color="#F814BF"
            />
          </Link>
          <h1>Edit Company</h1>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="name"
              value={companyForm.name}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="companyTicker">Company Ticker</label>
            <input
              type="text"
              id="companyTicker"
              name="ticker"
              value={companyForm.ticker}
              onChange={handleInputChange}
            />
          </div>

          {error && <div className="error text-red-400 italic">{error}</div>}

          <button type="submit" className="btn btn-primary">
            Update Company
          </button>
        </form>
      </section>
    </>
  );
};

export default CompanyEdit;
