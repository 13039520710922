import { useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../utils/ThemeManager.jsx";
;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie, faArrowRightFromBracket, faCircleHalfStroke, faClockRotateLeft, faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";

// import { toast } from 'react-toastify';

import RoleBadge from "../RoleBadge/RoleBadge.jsx";

import ieLogo from '../../assets/img/idea_evolver.png';
import spinner from '../../assets/img/spinner.svg';

import "./Header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { user: auth0User, isLoading, isAuthenticated, loginWithPopup, logout } = useAuth0();

  // Use the theme from ThemeManager
  const { theme, setTheme } = useTheme();

  const handleThemeToggle = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    // toast.success("Bye bye!");
    logout({ logoutParams: { returnTo: window.location.origin } })
  };

  // Determine the correct logo based on theme
  const logo = theme === "dark" ? ieLogo : ieLogo;
  const mobileLogo = theme === "dark" ? ieLogo : ieLogo;

  return (
    <header className="app-header">
      <div className="app-header-content">
        <div className="app-header-left">
          <Link onClick={() => setMenuOpen(false)} to="/leaderboard" className="app-header-logo">
            <picture>
              <source media="(min-width: 768px)" srcSet={logo} />
              <img src={mobileLogo} alt="GenTi" />
            </picture>
            <span className="sr-only">Welcome to GenTi</span>
          </Link>
        </div>
        <div className="app-header-right">
          {auth0User && !isLoading && (auth0User?.email || auth0User?.nickname) && (
            <div className="app-header-account-info">
              <button
                className="text-deep-space dark:text-white"
                onClick={handleThemeToggle}
                aria-label="theme toggle"
              >
                <FontAwesomeIcon icon={faCircleHalfStroke} />
              </button>

              <button
                className="border border-white grid grid-cols-1 grid-rows-1 place-items-center self-end justify-self-end"
                onClick={() => setMenuOpen(!menuOpen)}
                aria-label="account menu"
              >
                {auth0User.picture && (
                  <img
                    src={auth0User.picture}
                    alt={auth0User.name}
                    referrerPolicy="no-referrer"
                  />
                )}
                <RoleBadge />
              </button>

              <div
                className={`account-menu ${
                  menuOpen ? "block" : "hidden"
                }`}
                style={{ display: menuOpen ? "block" : "none" }}
              >
                <Link
                  className="app-header-link"
                  onClick={() => setMenuOpen(false)}
                  to="/admin"
                >
                  <FontAwesomeIcon icon={faUserTie} />
                  Admin
                </Link>
   
                <Link
                  className="app-header-link"
                  onClick={() => setMenuOpen(false)}
                  to="/admin/keyword-universe"
                >
                  <FontAwesomeIcon icon={faKey} />
                  Keywords
                </Link>

                <Link
                  className="app-header-link"
                  onClick={() => setMenuOpen(false)}
                  to="/project-status"
                >
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                  Update Queue
                </Link>

                <Link
                  className="app-header-link"
                  onClick={() => setMenuOpen(false)}
                  to="/profile"
                >
                  <FontAwesomeIcon icon={faUser} />
                  Profile
                </Link>
                
                <button
                  className="app-header-link"
                  onClick={handleLogout}
                  aria-label="sign out"
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                  Sign Out
                </button>
              </div>
            </div>
          )}

          { isLoading && (
            <div className="app-header-account-info">
              <img
                src={spinner}
                alt="loading"
                className="spinner"
              />
            </div>
          )}

          { !isAuthenticated && (
            <div className="space-x-8">
              <button
                className="text-deep-space dark:text-white"
                onClick={handleThemeToggle}
                aria-label="theme toggle"
              >
                <FontAwesomeIcon icon={faCircleHalfStroke} />
              </button>
              <button
                className="btn px-8"
                onClick={() => loginWithPopup()}
              >
                Sign In
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
