import { createContext, useContext, useEffect, useState } from "react";

import axios from 'axios';

export const ConfigContext = createContext()

export default function Provider({children}) {
  const [context, setContext] = useState({isLoading: true})

  useEffect(() => {
    const origin = window.location.origin == "http://localhost:4040" ? "http://localhost:8000" : ""

    axios.get(`${origin}/django_api/public/config`)
      .then(({ data }) => setContext({ ...data, isLoading: false }))
      .catch(() => setContext({ isLoading: false })); // Handle API errors gracefully

  }, [])

  return <ConfigContext.Provider value={context}>{children}</ConfigContext.Provider>
}

export const useConfig = () => useContext(ConfigContext)
