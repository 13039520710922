import { createContext, useContext, useEffect } from "react";

const UserContext = createContext();

const UserAuth = () => {
  return useContext(UserContext);
};

const AuthContextProvider = ({ children }) => {
  useEffect(() => {}, []);

  return (
    <UserContext.Provider value={{ }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserAuth, AuthContextProvider, UserContext }