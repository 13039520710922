import { useAuth0 } from '@auth0/auth0-react';

const Callback = () => {
  const { isLoading } = useAuth0();

  return (
    <div className='page-base callback-page'>
      {isLoading ? 'Processing login…' : 'Redirecting…'}
    </div>
  );
};

export default Callback;
