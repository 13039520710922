import {useEffect, useState} from 'react';
import { Link, useNavigate } from "react-router-dom";

import Paywall from '../../components/Paywall/Paywall';

import { $axios } from "../../services/axiosInstance";

import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
//import "./common.css"

function formatDate(d) {
  const x = new Date(d)
  return x.toLocaleDateString()
}

export function Subscription() {
  const [subscription, setSubscription] = useState()

  useEffect(() => {
    $axios.get("/public/subscription")
      .then(({data}) => setSubscription(data))
  }, [])

  if (!subscription) {
    return false
  }

  const { subscribed, status, card, period_end, cancel_at_period_end } = subscription
  // incomplete, incomplete_expired, trialing, active, past_due, canceled, unpaid, or paused
  if (!subscribed) {
    return (
      <div className='page-base subscription-page space-y-6'>
        <h1 className="mb-4 text-xl font-bold text-deep-space dark:text-[#C0D7ED]">Your Subscription</h1>
        <p>Upgrade your subscription to experience the full benefits of the platform</p>
        <Paywall />
      </div>
    )
  }

  if (status == "canceled") {
    return (
      <div>
        <h1 className="mb-4 text-xl font-bold text-deep-space dark:text-[#C0D7ED]">Your Subscription</h1>
        <div>subscription cancelled</div>
        <div><Link to="/subscription/checkout">add new subscription</Link></div>
      </div>
    )
  }

  const updateSubscription = (update) => {
    $axios.post("/subscription:update", update)
      .then(() => setSubscription({...subscription, ...update}))
  }

  const { display_brand, last4 } = card

  return (
    <div className='page-base subscription-page space-y-6'>
    <div>
      <h1 className="mb-4 text-xl font-bold text-deep-space dark:text-[#C0D7ED]">Your Subscription</h1>
      <div>
        <span className={`inline px-2 py-0.5 capitalize rounded bg-opacity-20 border ${status === 'active' ? 'border-green-500 bg-green-500' : 'border-watermelon-500 bg-watermelon-500'}`}>
          {status}
        </span>
      </div>
    </div>

    <div>
      <h2 className="mb-4 text-xl font-bold text-deep-space dark:text-[#C0D7ED]">Payment Information</h2>
      <div> {`${display_brand}: •••• •••• •••• ${last4}`}</div>
      <Link className='text-sm text-[#0561B7] dark:text-watermelon-200' to="/subscription/update">update payment method</Link>
    </div>

    { cancel_at_period_end 
      ? <div>
          <h2 className="mb-4 text-xl font-bold text-deep-space dark:text-[#C0D7ED]">Subscription renewal</h2>
          <div>subscription expires on {formatDate(period_end)}</div>
          <button className='text-sm text-[#0561B7] dark:text-watermelon-200 dark:hover:text-watermelon-500 hover:underline' onClick={() => updateSubscription({cancel_at_period_end: false})}>turn on renewal</button>
        </div>
      : <div>
          <h2 className="mb-4 text-xl font-bold text-deep-space dark:text-[#C0D7ED]">Subscription renewal</h2>
          <div>renews on {formatDate(period_end)}</div>
          <button className='text-sm text-[#0561B7] dark:text-watermelon-200 dark:hover:text-watermelon-500 hover:underline' onClick={() => updateSubscription({cancel_at_period_end: true})}>turn off renewal</button>
        </div>
    }

    </div>
  )
}



export function SubscriptionForm() {
  const navigate = useNavigate();

  async function fetchClientSecret() {
    try {
      const return_url = `${location.origin}/welcome`;
      const { data } = await $axios.post("/public/checkout_session", { return_url });
      return data.client_secret;
    } catch (error) {
      console.error("Failed to fetch client secret:", error);
      navigate("/");
      return null; // Return null to signal CheckoutForm not to render
    }
  }

  return (
    <div className='page-base subscription-form'>
      <CheckoutForm fetchClientSecret={fetchClientSecret} />
    </div>
  );
}

export function UpdateForm() {
  async function fetchClientSecret() {
    const return_url = `${location.origin}/subscription`
    const { data } = await $axios.post("/subscription/update_session", {return_url})
    return data.client_secret
  }

  return (
    <div className='page-base subscription-update-form'>
      <CheckoutForm fetchClientSecret={fetchClientSecret}/>
    </div>
  )
}

