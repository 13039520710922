import { Link } from "react-router-dom";

// hooks
import useProjectRefreshStatus from "../../../hooks/useProjectRefreshStatus";

// components
import RefreshButton from "../../../components/RefreshButton/RefreshButton";
import ProjectStatusIcon from "../../../components/ProjectList/ProjectStatusIcon";

import './ProjectsTable.css';

function ProjectsTable({ projects, loading }) {
  const { updatingProjects, setUpdatingProjects } = useProjectRefreshStatus();

  // Handle the empty or null array
  if (!projects || projects.length === 0) {
    return (
      <div className="text-center py-16">
        <h2>No results found</h2>
        <p>Try searching for a different company or brand</p>
      </div>
    );
  }

  return (
    <table
      className={`project-list grid ${loading ? "animate-pulse cursor-wait" : ""}`}
      data-testid="project-list"
    >
      <thead>
        <tr>
          <th className="text-left">
            <h2>Projects</h2>
          </th>
          <th className="text-left flex items-end">Company</th>
          <th className="text-left flex items-end">Last Run</th>
        </tr>
      </thead>
      <tbody>
        {projects.map((project) => (
          <tr className="project-row" key={project.uuid}>
            <td className="flex gap-2 items-center">
              <RefreshButton 
                refresh_property="project" 
                projectOrCondition={project} 
                updatingProjects={updatingProjects}
                setUpdatingProjects={setUpdatingProjects}
              />

              <div>
                { project.conditions.length > 0
                  ?<Link to={`/project/${project.uuid}`}>{project.project_name}</Link>
                  : project.project_name
                }

                { 
                  (project.conditions.length > 0 && project.conditions[0].name) && (
                    <div>
                      <span className="text-xs bg-[#0561B7] dark:bg-watermelon-500 bg-opacity-20 dark:bg-opacity-20 border border-deep-space dark:border-watermelon-500 border-dotted rounded px-1 py-0.5">
                        {project.conditions[0].name}
                      </span>
                    </div>
                  )
                }
              </div>
            </td>
            <td>
              <Link to={`/company/${project.company_uuid}`}>
                {project.company_name}
              </Link>
            </td>

            <td className="flex justify-start gap-2">
              <div className="icon-bg">
                <ProjectStatusIcon status={project.processing_status} />
              </div>
              {project.last_run
                ? new Date(project.last_run).toLocaleDateString()
                : "Never"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ProjectsTable;
