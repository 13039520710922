import { createContext, useContext, useEffect, useState, useCallback, useMemo } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useUser } from "./User";

import { $axios } from "../services/axiosInstance";

export const ProfileContext = createContext()

export default function Provider({children}) {
  const user = useUser();
  const { isLoading: isAuthLoading } = useAuth0();
  const [context, setContext] = useState({
    isLoading: true,
    genti_role: "",
    is_internal: false,
    is_internal_admin: false,
    subscription_info: {}
})

  useEffect(() => {
    if (isAuthLoading || !context.isLoading || !user.subscribed) return;

    $axios.get("/profile")
      .then(({ data }) => setContext({ ...data, isLoading: false }))
      .catch(() => setContext({ isLoading: false })); // Handle API errors gracefully

  }, [isAuthLoading, context.isLoading, user])

  const { isLoading } = context
  const contextValue = useMemo(() => ({
    isLoading,
    ...context,

  }), [isLoading, context])

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>
}

export const useProfile = () => useContext(ProfileContext)
