import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "../context/User.js";

import Paywall from "../components/Paywall/Paywall.jsx";

import useScrollToTop from "../hooks/useScrollToTop.js";

const ProtectedRoute = () => {
  const { subscribed, isLoading } = useUser();
  
  useScrollToTop();

  if(isLoading) {
    return null;
  }

  // if user does not exist, redirect to login page
  // save the current location to redirect back to after login
  if (subscribed) {
    return <Outlet />
  } else {
    // save the current location to redirect back to after login
    sessionStorage.setItem('redirectPath', window.location.pathname)
    // return <Navigate to="/" replace />
    return <div className="page-base">
      <Paywall>
        <h1 className="text-2xl font-bold">You need to upgrade your subscription to access this page</h1>
        <p>Choose a plan that suits your needs</p>
      </Paywall>
    </div>
  }
}

export default ProtectedRoute;
