import { useState, useEffect } from 'react';
import { $axios } from '../services/axiosInstance';

const useKeywordUniverse = (uuid) => {
  const [universe, setUniverse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUniverse = async () => {
      try {
        const { data } = await $axios.get(`company/keyword_universe/${uuid}/`);
        setUniverse(data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err);
        setLoading(false);
      }
    };

    fetchUniverse();
  }, [uuid]);

  return { universe, loading, error };
};

export default useKeywordUniverse;