import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@awesome.me/kit-d96c79f0ca/icons/classic/solid"

import './ToolTipLabel.css'


const ToolTipLabel = ({ labelText, htmlFor, toolTipText, elValue }) => {
  const [hovered, setHovered] = useState(false);

  return <div className="label-group">
  <label htmlFor={htmlFor}>
    { labelText }
    <FontAwesomeIcon 
      icon={faCircleQuestion} 
      aria-haspopup="true"
      aria-expanded={hovered ? "true" : "false"}
      data-state={hovered ? "open" : "closed"}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  </label>
  <span className="range-data">
    { elValue }
  </span>

  <div 
    className="tooltip"
    role="tooltip"
    style={{ 
      opacity: hovered ? 1 : 0, 
      pointerEvents: hovered ? "all" : "none", 
    }}
    aria-hidden={hovered ? "false" : "true"}>
    { toolTipText }
  </div>
</div>
}

export default ToolTipLabel;