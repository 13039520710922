import Paywall from "../../components/Paywall/Paywall";

function Prices() {
  return (
  <div className='page-prices page-base'>
    <Paywall />
  </div>
  )
}

export default Prices;
