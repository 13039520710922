// react
import { useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import ChartSlider from "../../components/ChartSlider/ChartSlider";
import UniverseCharts from "../KeywordUniverse/Visualizations/UniverseCharts/UniverseCharts";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartPie, faChevronRight } from '@awesome.me/kit-d96c79f0ca/icons/classic/solid'

// global components
import Paywall from "../../components/Paywall/Paywall";
import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import RefreshButton from "../../components/RefreshButton/RefreshButton";
import ProjectInfoHeader from "../../components/ProjectInfoHeader/ProjectInfoHeader";

// local components
import MedicationOverview from "./Visualizations/MedicationOverview/MedicationOverview";

// other components
import SearchVolBubble from "../Clusters/Visualizations/SearchVolBubble";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";
import useProjectRefreshStatus from "../../hooks/useProjectRefreshStatus";

// context
import { useUser } from "../../context/User";

import "./Project.css";

export function isLongerThanDate(date) {
  // threshhold date is sept 1 2024
  const threshhold = new Date("2024-09-01");
  const lastRun = new Date(date);

  return threshhold - lastRun > 30 * 24 * 60 * 60 * 1000;
}

const Project = () => {
  const location = useLocation();
  const history = location.state?.history || [];
  const user = useUser();
  const uuid = useParams().projectId;

  const {
    project,
    loading: loadingProject,
    error: errorProject,
  } = useProject(uuid);
  const {
    brandData,
    loading: loadingBrand,
    error: errorBrand,
  } = useBrandAnalysis(uuid);
  const { updatingProjects, setUpdatingProjects } = useProjectRefreshStatus();

  const [activeConditionIndex, setActiveConditionIndex] = useState(0);

  if (loadingProject || loadingBrand || user?.isLoading) {
    return <Loading />;
  }

  if (errorProject || errorBrand) {
    const errorStatus = errorProject?.status || errorBrand?.status;
    if (!user || Object.keys(user).length === 0 || user?.subscribed === false && errorStatus === 402) {
      return (
        <Paywall>
          <h1 className="text-2xl">
            Upgrade your plan to view this project
          </h1>
          <p className="text-center">
            Your current free tier allows limited access. To view this project in full, please upgrade your subscription.
          </p>
        </Paywall>
      );
    }

  
    // Fallback for other errors
    return (
      <>
        <Helmet>
          <title>Genti - Project</title>
        </Helmet>
        <div className="project-page page-base">
          <ErrorDisplay error={errorProject || errorBrand} />
        </div>
      </>
    );
  }
  
  if (project?.conditions?.length === 0) {
    return <>
      <Helmet>
        <title>Genti - Project</title>
      </Helmet>
      <div className="flex flex-col justify-center items-center">
        <p className="px-4 py-8 font-bold grid place-content-center">
          This project cannot be displayed because it does not have an
          associated condition.
        </p>
        <Link className="btn w-full md:max-w-[500px]" to="/leaderboard">
          Back to Leaderboard
        </Link>
      </div>
    </>;
  }

  if (isLongerThanDate(project.last_run)) {
    // only projects that have a condition analysis and have
    return <>
      <Helmet>
        <title>Genti - Project</title>
      </Helmet>

      <div className="p-4 flex flex-col justify-center items-center space-y-8">
        <h1 className="text-2xl">
          This project must be refreshed prior to viewing
        </h1>
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="p-2 text-5xl rounded hover:border hover:border-[#0561B7]">
            <RefreshButton
              refresh_property="project"
              projectOrCondition={project}
              project_eligible_for_analysis={project.eligible_for_analysis}
              updatingProjects={updatingProjects}
              setUpdatingProjects={setUpdatingProjects}
            />
          </div>

          <Link to="/leaderboard" className="btn w-full md:min-w-[500px]">
            Back to Leaderboard
          </Link>
          <Link to="/project-status" className="btn w-full md:min-w-[500px]">
            View Project Status
          </Link>
        </div>
      </div>
    </>;
  }

  return <>
    <Helmet>
      <title>{project.name || ""} | Genti</title>
      <meta property="og:title" content={project.name || ""} />
      <meta property="og:description" content="A new horizon. Empowering self-care." />
      <meta property="og:image" content="https://genti.ai/og-image.png" />
      <meta property="og:url" content={`https://genti.ai/project/${uuid}`} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://genti.ai/og-image.png" />
      <meta name="twitter:title" content="Genti" />
      <meta name="twitter:description" content="A new horizon. Empowering self-care." />
      <meta name="twitter:site" content="@ideaevolver" />
    </Helmet>

    <div className="project-page page-base">
      <section>
        <ProjectInfoHeader className="mb-2" project={project}>
          {uuid === "e8d32602-0897-44a4-a14a-5152c1d44fdc" ? (
            <Link
              className="btn max-w-[206px]"
              to={`/project/${uuid}/acnu_dashboard`}
              state={{
                from: `/project/${uuid}`,
                history: [
                  ...history,
                  { name: project.name, path: `/project/${uuid}` },
                ],
              }}
            >
              ACNU Dashboard
              <FontAwesomeIcon className="ml-2" icon={faChartPie} />
            </Link>
          ) : (
            <button className="bg-gray-500 text-white rounded-sm px-4 py-3 font-bold flex justify-center items-center gap-4 cursor-not-allowed" disabled>
              ACNU Dashboard
              <FontAwesomeIcon icon={faChartPie} />
            </button>
          )}
        </ProjectInfoHeader>

        <div className="page-section">
          <h2 className="section-header flex justify-between items-center">
            Overview of {project.name}
          </h2>

          <MedicationOverview
            project={project}
            brand={brandData}
            loading={loadingBrand || loadingProject}
          />
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/clusters/${project.conditions[activeConditionIndex].uuid}`}
          >
            <div>
              { project.conditions[activeConditionIndex].name } Interest Journey
              { !user?.subscribed && <span className="ml-2 whitespace-nowrap font-bold text-[11px] bg-[#0561B7] text-white dark:bg-watermelon-500 rounded-full px-2 py-0.5 shadow-sm leading-[2]">Subscription Only</span> }
            </div>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <ChartSlider getActiveIndex={setActiveConditionIndex}>
            {
              // for each condition in project.conditions, render a UniverseCharts component
              project.conditions.length > 0 
                ? project.conditions.map((condition, index) => (
                  <UniverseCharts
                    key={index}
                    data={condition.keyword_universe}
                    condition={condition.name}
                    uuid={condition.uuid}
                    category_type="Condition"
                  />
                ))
                : <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">
                    { project.name } has no conditions
                  </p>
            }
        </ChartSlider>
      </section>

      {/* <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/project/${uuid}/keyword-universe`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                { name: project.name, path: `/project/${uuid}` },
              ],
            }}
          >
            Keyword Universe
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <SearchVolMonthType
          data={project?.keyword_universe}
          project_name={project?.name}
        />
      </section> */}

      {/* <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/project/${uuid}/competitive-analysis`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                { name: project.name, path: `/project/${uuid}` },
              ],
            }}
          >
            Competitive Analysis
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <DirectCompetitorChart data={brandData.competitors} />
      </section> */}

      <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/clusters/${uuid}`}
          >
            <div className="relative">
              { project.name } Interest Journey
              { !user?.subscribed && <span className="ml-2 whitespace-nowrap font-bold text-[11px] bg-[#0561B7] text-white dark:bg-watermelon-500 rounded-full px-2 py-0.5 shadow-sm leading-[2]">Subscription Only</span> }
            </div>
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <ChartSlider>
          <UniverseCharts data={project?.keyword_universe} uuid={uuid} project_name={project?.name} category_type="Branded" /> 
          
          <SearchVolBubble
            data={project.search_volume_by_type}
            model_name={project.name}
            clusterId={uuid}
          />

        </ChartSlider>
      </section>

      {/* <section className="page-section">
        <h2 className="section-header">
          <Link
            className="flex justify-between items-center"
            to={`/project/${uuid}/acnu-feasibility`}
            state={{
              from: `/project/${uuid}`,
              history: [
                ...history,
                { name: project.name, path: `/project/${uuid}` },
              ],
            }}
          >
            ACNU Forecast
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        </h2>

        <AnnualForecast data={project?.keyword_universe} />
      </section> */}
    </div>
  </>;
};

export default Project
