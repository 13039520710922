import { useState } from "react";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@awesome.me/kit-d96c79f0ca/icons/classic/solid"

import { toast } from 'react-toastify';

// axios
import { $axios } from "../../services/axiosInstance";
import { useProfile } from "../../context/Profile";

const RefreshButton = ({ refresh_property, projectOrCondition, updatingProjects, setUpdatingProjects }) => {
  const { is_internal } = useProfile();
  const [loading, setLoading] = useState(false);
  const refreshMap = {
    'project': {
      endpoint: 'complete_project_refresh_with_semrush',
      button_display: 'Refresh Project Data',
    },
    'competitiveLandscape': {
      endpoint: 'refresh_competitive_landscape',
      button_display: 'Refresh Competitive Landscape data',
    },
    'competitors': {
      endpoint: 'refresh_competitors',
      button_display: 'Refresh Direct Competitor data',
    },
    'demographicAnalysis': {
      endpoint: 'refresh_demographic_analysis',
      button_display: 'Refresh Demographic Analysis data',
    },
    'keywordList': {
      endpoint: 'refresh_keyword_list',
      button_display: 'Refresh Keyword List data',
    },
    'searchChart': {
      endpoint: 'refresh_search_chart_data',
      button_display: 'Refresh Consumer Search data',
    },
    'swotAnalysis': {
      endpoint: 'refresh_swot_analysis',
      button_display: 'Refresh S.W.O.T. Analysis data',
    },
  }

  const handleRefresh = async () => {
    setLoading(true);

    // condition
    if (projectOrCondition.model_type === 'Condition') {
      try {
        await $axios.put(`/company/condition_analysis/${projectOrCondition.uuid}/refresh_condition_with_semrush/`)
        toast.success('Condition data is being refreshed. This may take a few minutes.');
      } catch (e) {
        console.error(e);
        toast.error('Failed to refresh condition data');
        setLoading(false);
      }
    } else {
      // project
      try {
        await $axios.put(`/company/project/${projectOrCondition.uuid}/${refreshMap[refresh_property].endpoint}/`)
        toast.success('Project data is being refreshed. This may take a few minutes.');
        setUpdatingProjects([...updatingProjects, {...projectOrCondition, status: 'Queued'}]);
      } catch (e) {
        console.error(e);
        toast.error('Failed to refresh project data');
        setLoading(false);
      }
    }
  }

  const shouldSpinIcon = () => {
    return loading || projectOrCondition.processing_status === 'Queued' || projectOrCondition.processing_status === 'In Progress';
  };

  const isButtonDisabled = () => {
    return (
      !projectOrCondition ||
      loading ||
      projectOrCondition.processing_status === 'Queued' ||
      projectOrCondition.processing_status === 'In Progress' ||
      projectOrCondition.eligible_for_analysis !== true ||
      projectOrCondition.condition_analysis_name === null ||
      projectOrCondition.conditions?.length === 0
    );
  };

  if(is_internal === false ) {
    return null;
  }
  
  return (
    <button
      className={`refresh-btn hover:text-[#0561B7] dark:hover:text-watermelon-500 aspect-square disabled:opacity-50`}
      title={
        projectOrCondition.model_type === 'Condition' 
        ? 'Refresh Condition Data'
        : refreshMap[refresh_property].button_display
      }
      onClick={handleRefresh}
      disabled={isButtonDisabled()}
      data-testid="refresh-button"
    >
      <FontAwesomeIcon icon={faArrowsRotate} spin={shouldSpinIcon()} />
      <span className="sr-only">
        { refreshMap[refresh_property].button_display }
      </span>
    </button>
  )
}

export default RefreshButton;