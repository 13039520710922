import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { router } from "./router";

import "./App.css";
import "tailwindcss/tailwind.css";

function App() {
  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;
