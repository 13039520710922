import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useUser } from "../../context/User"

function sleep(ms) {
  return new Promise((res) => setTimeout(res, ms))
}

export function SubscriberWelcome() {
  const { isAuthenticated } = useAuth0()
  const { subscribed, getSubscription } = useUser()

  useEffect(() => {
    if (!isAuthenticated) { return; }
  
    (async () => {
      for (;;) {
        try {
          const { subscribed } = await getSubscription();
          if (subscribed) {
            return;
          }
        } catch (error) {
          console.error('getSubscription failed:', error); // Log the error
          // Continue polling instead of stopping
        }
        await sleep(1000);
      }
    })();
  }, [isAuthenticated]);

  if (!subscribed) {
    return (
      <div>
        creating subscription...
      </div>
    )
  }

  return (
    <div className="page-base subscriber-welcome-page">
      <h1>subscription created</h1>
      <div><Link to="/subscription">click here to manage your subscription</Link></div>
    </div>
  )
}

export default SubscriberWelcome;
