import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './Missing.css';

const Missing = () => (<>
  <Helmet>
    <title>404 | Genti</title>
    <meta property="og:title" content="Genti" />
    <meta property="og:description" content="A new horizon. Empowering self-care." />
    <meta property="og:image" content="https://genti.ai/og-image.png" />
    <meta property="og:url" content="https://genti.ai" />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en_US" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content="https://genti.ai/og-image.png" />
    <meta name="twitter:title" content="Genti" />
    <meta name="twitter:description" content="A new horizon. Empowering self-care." />
    <meta name="twitter:site" content="@ideaevolver" />
  </Helmet>

  <div className="missing page-base">
    <h1>404</h1>
    <p>
      The page you are looking for does not exist. Please check the URL or <Link to="/leaderboard">return home</Link>.
    </p>
  </div>
</>);

export default Missing;