import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { useTheme } from "../../../../utils/ThemeManager.jsx";
import { formatMonths } from "../../../../utils/chartUtils.js";
import './SearchOpportunity.css';

function SearchOpportunity({ universe, condition_analysis }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (universe == null || Object.keys(universe).length === 0) return;

    setOptions(chartOptions(universe, theme));
  }, [theme]);

  if ( universe == null || Object.keys(universe).length === 0 ||
    !universe['Grand Total'] || universe['Grand Total'].search_volume_avg_annual_total === 0
    || !condition_analysis || Object.keys(condition_analysis).length === 0
    ) {
    return <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">Search Spend Rec data is not available.</p>
  }

return <div className="search-opportunity">
  <div className="content">
    <div className="col col-1">
      <h4>Summary</h4>

      <div className="space-y-4 p-4">
        <p>
          There were ~{ universe['Grand Total'].search_volume_avg_annual_total.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") } searches performed in the past 12 months.
        </p>

        <Markdown>{ condition_analysis?.untreated_vs_treated_chartinfo?.description || "" }</Markdown>
      </div>
    </div>
    <div className="col col-2">
      <ChartDashboard config={options} id="search-opportunity-container" />
    </div>
  </div>
</div>
} 

export default SearchOpportunity;

const chartOptions = (data, theme) => {
  const totalSearchVolume = data['Grand Total'].search_volume_avg_annual_total;
  const brandPercentage = (data['Brand Subtotal'].search_volume_avg_annual_total / totalSearchVolume) * 100;
  const conditionPercentage = (data['Condition Subtotal'].search_volume_avg_annual_total / totalSearchVolume) * 100;
  const searchVolumeMonths = data['Grand Total'].search_volume_months
  const colors = {
    dark: ["#FF7347", "#3AE500"],
    light: ['#0561B7', '#FF7347'],
  }

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `search-opportunity-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `search-opportunity-cell`,
        chartOptions: {
          colors: colors[theme],
          chart: {
            height: 436,
            styledMode: false,
          },
          title: {
            text: 'Search Interest for "High Cholesterol" in U.S.',
            align: 'left',

          },
          xAxis: {
            categories: formatMonths(searchVolumeMonths)
          },
          yAxis: {
            title: {
              text: '',
            },
            labels: {
            },
            min: 0,
          },
          legend: {
            enabled: false
          },
          series: [{
            type: 'line',
            name: 'Search Interest',
            label: null,
            data: data['Grand Total'].search_volume_monthly_totals,
            marker: {
              fillColor: theme === 'dark' ? '#020021' : '#FFFFFF',  
              lineColor: colors[theme][0]
            },
          }, 
          {
            type: 'pie',
            name: null,
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              headerFormat: '',
              pointFormatter: pointFormatter(theme, colors),
            },
            data: [
              { 
                name: 'Brand', 
                y: brandPercentage,
              },
              { 
                name: 'Condition', 
                y: conditionPercentage,
              }
            ],
            center: ['90%', '80%'],
            size: 150,
            innerSize: '70%',
            showInLegend: false,
            legend: {
              enabled: true
            },
          }
        ]
        }
      },
    ],
  };
};

export const pointFormatter = function (theme, colors) {
  return function () {
    const colorIndex = this.colorIndex || 0;
    const color = colors[theme][colorIndex];
    return `<span style="color:${color}">●</span> ${this.name}: <b>${this.percentage.toFixed(1)}%</b>`;
  };
};
