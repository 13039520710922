import { useEffect, useRef, useState } from "react";
import { Link, useParams, useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMagnifyingGlass, faKey, faCaretUp, faCaretDown } from '@awesome.me/kit-d96c79f0ca/icons/classic/solid'
import { formatFloat } from "../../utils/tableUtils";
import { generateGradientColors, hexToRgb } from "../../utils/chartUtils";

import Paywall from "../../components/Paywall/Paywall";
import Loading from "../../components/Loading/Loading";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay";
import ProjectInfoHeader from "../../components/ProjectInfoHeader/ProjectInfoHeader";
import ExternalLink from "../../components/ExternalLink/ExternalLink";
import ChartSlider from "../../components/ChartSlider/ChartSlider";
import UniverseCharts from "./Visualizations/UniverseCharts/UniverseCharts";
import SearchVolBubble from "./Visualizations/SearchVolBubble";


import { useTheme } from "../../utils/ThemeManager";
import useProject from "../../hooks/useProject";
import useClusters from "./hooks/useClusters";

import { useUser } from "../../context/User";
import { useProfile } from "../../context/Profile";

import "./Clusters.css";

export const matchesQuery = (item, query) => {
  const lowerQuery = query.toLowerCase();
  const { title, summary, popular_questions, visualizations } = item.cluster_data;
  return (
    title.toLowerCase().includes(lowerQuery) ||
    (summary && summary.toLowerCase().includes(lowerQuery)) ||
    (popular_questions && popular_questions.some(q => q.question.toLowerCase().includes(lowerQuery))) ||
    (visualizations.keywords && visualizations.keywords.some(v => v.value.toLowerCase().includes(lowerQuery)))
  );
};

function Clusters() {
  const user = useUser();
  const location = useLocation();
  const { isLoading: isLoadingProfile, is_internal } = useProfile();
  const { theme } = useTheme();
  const { clusterId } = useParams();
  const { project, loading: loadingProject } = useProject(clusterId);
  const { clusterFeed, clusterName, clusterType, loading, error: errorCluster } = useClusters(clusterId);
  const categoryOptions =
  clusterType === "Brand Project"
    ? [
        "General Branded",
        "Payment",
        "Usage",
        "Side Effects",
        "Evaluation",
        "Assistance",
      ]
    : [
        "General Condition",
        "Symptoms",
        "Diagnosis",
        "Treatment",
        "Preventative",
        "Clinical Trials",
        "Support",
      ];
  const [sortConfig, setSortConfig] = useState({
    key: "total_search_volume",
    direction: "descending",
  });
  const [colors, setColors] = useState({
    dark: [],
    light: [],
  }); 


  const [searchParams] = useSearchParams();
  let category = searchParams.get('category') || 'All';
  //if category is not in the list, set it to All
  if (!categoryOptions.includes(category)) {
    category = 'All';
  }


  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(category);
  // to focus and clear search input
  const searchInputRef = useRef(null);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === "ascending" ? (
        <FontAwesomeIcon className="ml-2" icon={faCaretUp} />
      ) : (
        <FontAwesomeIcon className="ml-2" icon={faCaretDown} />
      );
    }
    return null;
  };

  const queries = searchQuery
    .split(",")
    .map((q) => q.trim().toLowerCase())
    .filter((query) => query.length > 0);

    const filteredClusterFeed = clusterFeed.filter((item) => {
      if (!searchQuery.trim()) return true;
      return queries.some((query) => matchesQuery(item, query));
    }).filter(
      (item) =>
        selectedCategory === "All" || item.cluster_data.primary_category === selectedCategory
    );

  const sortedClusterFeed = [...filteredClusterFeed].sort((a, b) => {
    let aValue = a.cluster_data[sortConfig.key] || 0;
    let bValue = b.cluster_data[sortConfig.key] || 0;
    return sortConfig.direction === "ascending"
      ? aValue - bValue
      : bValue - aValue;
  });

  const handleClearSearch = () => {
    setSearchQuery("");

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setColors({
      dark: generateGradientColors("#FF3C6D", "#FF7347", queries.length),
      light: generateGradientColors("#020021", "#0561B7", queries.length),
    });
  }, [theme, searchQuery]);

  if (loading || loadingProject || isLoadingProfile) {
    return <Loading />;
  }

  if (!user || Object.keys(user).length === 0 || (user?.subscribed !== true && (is_internal ?? false) === false)) {
    return (
      <div className="page-base">
        <Paywall>
          <h1 className="text-2xl">
            Upgrade your plan to view this feed
          </h1>
          <p className="text-center">
            Your current free tier allows limited access. To view this cluster feed, please upgrade your subscription.
          </p>
        </Paywall>
      </div>
    );
  }

  if (errorCluster) {
    return (
      <>
        <Helmet>
          <title>Genti - Cluster</title>
        </Helmet>
        <div className="project-page page-base">
          <ErrorDisplay error={errorCluster} />
        </div>
      </>
    );
  }

  if (clusterFeed.length === 0) {
    return (<>
      <Helmet>
        <title>{`Cluster Feed | Genti`}</title>
      </Helmet>

      <div className="page-base cluster-feed space-y-4">
        <section className="page-section">
          <h1 className="section-header">Cluster Feed</h1>
          <div className="p-8 flex flex-col gap-4 justify-center items-center">
            <h2 className="text-2xl text-semibold text-center">
              No cluster feed available.
            </h2>
            <Link
              to={location.state?.from || "/leaderboard"}
              className="btn w-full max-w-[400px]"
            >
              {location.state?.from ? "Back" : "Back to Leaderboard"}
            </Link>
          </div>
        </section>
      </div>
    </>);
  }

  return <>
    <Helmet>
      <title>{`${clusterName} | Genti`}</title>
      <meta property="og:title" content={`${clusterName || ''} Cluster Feed | Genti`} />
      <meta property="og:description" content="A new horizon. Empowering self-care." />
      <meta property="og:image" content="https://genti.ai/og-image.png" />
      <meta property="og:url" content="https://genti.ai" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content="https://genti.ai/og-image.png" />
      <meta name="twitter:title" content="Genti" />
      <meta name="twitter:description" content="A new horizon. Empowering self-care." />
      <meta name="twitter:site" content="@ideaevolver" />
    </Helmet>

    <div className="page-base cluster-feed space-y-4">
      { clusterType === "Brand Project" 
        && project !== null
        ? <ProjectInfoHeader project={project} linkToProjectPage={true} />
        : <h1 className="text-2xl font-semibold">{clusterName}</h1>
      }

      <section className="page-section">
        <h2 className="section-header">Cluster Feed</h2>

        <div className="grid grid-cols-10 grid-rows-3 md:grid-rows-1 gap-2 lg:gap-y-1 p-2 lg:px-4 lg:pt-7">
          <div className="relative col-span-full md:col-span-6">
            <input
              className="w-full input px-4 py-2 border border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50 focus:ring focus:ring-[#0561b780] focus:dark:ring-[#0561b7]"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              placeholder="Search for clusters (comma-separated values allowed)"
              ref={searchInputRef}
            />
            { searchQuery !== '' && <button
              className="absolute top-1/2 right-2 -translate-y-1/2 rounded-full h-10 w-10 text-lg hover:bg-black hover:bg-opacity-10 dark:hover:bg-white dark:hover:bg-opacity-20"
              onClick={handleClearSearch}
              type="button"
            >
              <FontAwesomeIcon icon={faClose} />
            </button>}
          </div>

          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="input col-span-full md:col-span-2 px-4 py-2 border border-[#0561B7] border-opacity-[0.25] dark:border-[#0561B7] dark:border-opacity-50 focus:ring focus:ring-[#0561b780] focus:dark:ring-[#0561b7]"
          >
            <option value="All">All Categories</option>
            {categoryOptions.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>

          <div className="col-span-full md:col-span-2 flex gap-1 md:gap-2 border border-[#0561B7] border-opacity-50 dark:bg-[#0561B7] dark:bg-opacity-10 rounded">
            <button
              onClick={() => requestSort("total_search_volume")}
              className="w-full lg:min-w-[70px]"
              title="Sort by Total Search Volume"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <span className="sr-only">Total Search Volume</span>
              {getSortArrow("total_search_volume")}
            </button>
            <button
              onClick={() => requestSort("total_keyword_count")}
              className="w-full lg:min-w-[70px]"
              title="Sort by Total Keyword Count"
            >
              <FontAwesomeIcon icon={faKey} />
              <span className="sr-only">Total Keyword Count</span>
              {getSortArrow("total_keyword_count")}
            </button>
          </div>
        </div>

        <div className="hidden md:block">
          <ChartSlider>
            { clusterType && clusterType === "Brand Project" && <UniverseCharts uuid={clusterId} category_type="Branded" setSelectedCategory={setSelectedCategory} /> }
            { clusterType && clusterType === "Condition Analysis" && <UniverseCharts uuid={clusterId} category_type="Condition" setSelectedCategory={setSelectedCategory} /> }
            <SearchVolBubble 
              data={filteredClusterFeed} 
              model_name={clusterName} 
              clusterId={clusterId} 
              searchQuery={searchQuery} 
            />
          </ChartSlider>
        </div>

        <div>
          <ul className="cluster-list">
            {sortedClusterFeed.map((item, index) => {
              const itemQueryIndex = queries.findIndex((query) =>
                item.cluster_data.title.toLowerCase().includes(query)
              );
              const itemColor =
                itemQueryIndex !== -1
                  ? colors[theme][itemQueryIndex % colors[theme].length]
                  : theme === 'dark' ? "#FF3C6D" : "#0561B7";
              return (
                <li
                  key={index}
                  className="relative page-section cluster-item"
                  style={{
                    "--hover-color": itemColor,
                    '--hover-color-rgb': hexToRgb(itemColor),
                  }}
                >
                  <div style={{ 
                      backgroundColor: `rgba(${hexToRgb(itemColor)}, 0.6)`,
                      border: `1px solid ${itemColor}` 
                    }} 
                    className={`indicator absolute -bottom-4 -left-4 h-8 w-8 bg-[${itemColor}] rounded-full`}
                  />
                  <ExternalLink
                    title={item.cluster_data.title}
                    className="cluster-link"
                    url={`/cluster/${clusterId}/${item.uuid}`}
                  >
                    <h2 className="section-header">
                      <span>{item.cluster_data.title || "No summary"}</span>
                    </h2>
                    <p className="p-4 pb-0">
                      <span>{item.cluster_data.summary || "No summary"}</span>
                    </p>
                    <div
                      title="Total Search Volume"
                      className="flex justify-between items-end gap-4 px-4 py-2 text-sm"
                    >
                      <div className="text-xs">
                        {item.cluster_data.primary_category || ""}
                      </div>
                      <div className="flex gap-4">
                        <div className="flex gap-2 items-center">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                          <span>
                            {formatFloat(item.cluster_data.total_search_volume, 0)}
                          </span>
                        </div>
                        <div
                          title="Total Keyword Count"
                          className="flex gap-2 items-center"
                        >
                          <FontAwesomeIcon icon={faKey} />
                          <span>
                            {formatFloat(item.cluster_data.total_keyword_count, 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </ExternalLink>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </div>
  </>;
}

export default Clusters;
