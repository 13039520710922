import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from '@awesome.me/kit-d96c79f0ca/icons/classic/solid'

import { $axios } from "../../services/axiosInstance";

const ProfileForm = () => {
  const { user, isLoading } = useAuth0();
  const [formData, setFormData] = useState({
    given_name: "",
    family_name: "",
    email: "",
    nickname: "",
  });

  useEffect(() => {
    
    if (user) {
      setFormData({
        given_name: user.given_name || "",
        family_name: user.family_name || "",
        email: user.email || "",
        nickname: user.nickname || "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fullName = `${formData.given_name} ${formData.family_name}`.trim();
    const updatedData = { ...formData, name: fullName };
    console.log("Updated profile data:", updatedData);
  };

  if (isLoading) {
    return (
      <div className="page-base profile-page mx-auto p-4">
        <section className="page-section">
          <h1 className="section-header">Loading...</h1>
        </section>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="page-base profile-page mx-auto p-4">
      <section className="page-section">
        <h1 className="section-header">Update Your Profile</h1>
        <div className="mb-8 pt-4">
          { user && <img className="mx-auto rounded-full" src={user.picture} alt={user.name} />}
        </div>
        <form className="max-w-screen-md mx-auto mb-8 grid md:grid-cols-2 gap-4 p-4 lg:p-0" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="given_name" className="block mb-1 text-sm">
              First Name
            </label>
            <input
              type="text"
              id="given_name"
              name="given_name"
              value={formData.given_name}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label htmlFor="family_name" className="block mb-1 text-sm">
              Last Name
            </label>
            <input
              type="text"
              id="family_name"
              name="family_name"
              value={formData.family_name}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label htmlFor="nickname" className="block mb-1 text-sm">
              Username
            </label>
            <input
              type="text"
              id="nickname"
              name="nickname"
              value={formData.nickname}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div>
            <label htmlFor="email" className="mb-1 text-sm flex justify-between">
              Email { user?.email_verified
                ? (<span><FontAwesomeIcon icon={faCircleCheck} className="text-green-500" /> Verified</span>)
                : <button type="button" className="text-sm underline">Verify email</button>
              }
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="flex flex-col justify-center items-center mt-4 md:col-span-full">
            <button
              type="submit"
              className="w-full max-w-[450px] btn hover:bg-blue-600 transition"
            >
              Update Profile
            </button>
          </div>
        </form>
        <div className="w-full flex justify-end gap-4 p-4">
          <Link to="/subscription" className="text-sm underline hover:text-watermelon-500">
            Manage subscription
          </Link>
          { user?.subscribed && <>
            <button type="button" className="text-sm underline hover:text-watermelon-500">
              Pause subscription
            </button>
            <button type="button" className="text-sm underline hover:text-watermelon-500">
              Cancel subscription
            </button>
          </> }
        </div>
      </section>
    </div>
  );
};

export default ProfileForm;
