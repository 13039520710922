import { useState, useEffect } from 'react';
import { $axios } from '../../../services/axiosInstance';
import { useUser } from '../../../context/User';
import { useProfile } from '../../../context/Profile';

const useClusters = (clusterId) => {
  const user = useUser();
  const { is_internal } = useProfile();
  const [clusterFeed, setClusterFeed] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [clusterType, setClusterType] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCluster = async () => {
      if (!user?.subscribed && !is_internal) {
        setLoading(false);
        return;
      }

      try {
        const { data } = await $axios.get(`/cluster/${clusterId}/`);

        if (data == null || data.clustering_results?.clusters?.length === 0) {
          setLoading(false);
          return;
        }

        const clusterInfo = data.clustering_results?.cluster_info || [];
        const searchVolumeByType = data.clustering_results?.search_volume_by_type || [];

        // Merge clusterInfo and searchVolumeByType based on matching uuid
        const mergedClusterFeed = clusterInfo.map((cluster) => {``
          const matchingVolume = searchVolumeByType.find((vol) => vol.uuid === cluster.uuid);

          return {
            ...cluster,
            ...(matchingVolume ? { ...matchingVolume } : {}),
          };
        });

        setClusterFeed(mergedClusterFeed);
        setClusterName(data.model_name);
        setClusterType(data.model_type);
      } 
      catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCluster();
  }, [clusterId]);

  return { clusterFeed, clusterName, clusterType, loading, error };
};

export default useClusters;
