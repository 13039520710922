// react
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

// hooks
import useProjectRefreshStatus from "../../hooks/useProjectRefreshStatus";

import ProjectDifficultyIcon from "../../components/ProjectList/ProjectDifficultyIcon";
import RefreshButton from "../../components/RefreshButton/RefreshButton";

import ExternalLink from "../../components/ExternalLink/ExternalLink";

// styles
import "./ProjectStatus.css";
import RefreshStatus from "./RefreshStatus/RefreshStatus";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ProjectStatus = () => {
  const { updatingProjects, setUpdatingProjects, updatedProjects, semrushAvailable, unitsAvailable } = useProjectRefreshStatus();
  const [queuedProjects, setQueuedProjects] = useState([]);
  const [inProgressProjects, setInProgressProjects] = useState([]);
  const [successProjects, setSuccessProjects] = useState([]);
  const [stuckProjects, setStuckProjects] = useState([]);
  const [failedProjects, setFailedProjects] = useState([]);
  const [semrushStatus, setSemrushStatus] = useState("unavailable");

  useEffect(() => {
    // Set the SEMrush status
    setSemrushStatus(semrushAvailable ? "available" : "unavailable");

    // Filter the updatingProjects array by status
    const queued = updatingProjects.filter(
      (project) => project.processing_status === "Queued"
    );
    setQueuedProjects(queued);

    const inProgress = updatingProjects.filter(
      (project) => project.processing_status === "In Progress"
    );
    setInProgressProjects(inProgress);

    const success = updatedProjects.filter(
      (project) => project.processing_status === "Success"
    );
    setSuccessProjects(success);

    const stuck = updatedProjects.filter(
      (project) => project.processing_status === "Stuck"
    );
    setStuckProjects(stuck);

    const failed = updatedProjects.filter(
      (project) => project.processing_status === "Failure"
    );
    setFailedProjects(failed);
  }, [updatingProjects, updatedProjects]); // Dependency on updatingProjects

  return (
    <>
      <Helmet>
        <title>Genti - Project Status</title>
      </Helmet>

      <div className="project-status-page page-base">
        <div className="page-header">
          <div className="col-1">
            <h1 className="text-3xl md:text-4xl font-semibold mb-1">
              Project Status
            </h1>
            <p className="ml-3 text-[#838190]">
              View the status of all projects.
            </p>
          </div>

          <div className="col-2">
            <div className="semrush-status">
              <h2 className="sr-only">SEM Rush Status</h2>
              <div className="status-item">
                <h3>Available Units</h3>
                <p>{unitsAvailable ? unitsAvailable.toLocaleString() : 0}</p>
              </div>
              <div className="status-item ">
                <h3>SEMRush Status</h3>
                <p className={semrushStatus}>{semrushStatus}</p>
              </div>
            </div>
          </div>
        </div>

        <section className="page-section queued">
          <div className="table-container overflow-x-scroll w-full">
            <table className="project-table status">
              <thead>
                <tr>
                  <th className="align-middle">
                    <h2>Queued</h2>
                  </th>
                  <th>Company</th>
                  <th className="text-center">ACNU Difficulty</th>
                  <th className="text-center">Run Initiated</th>
                  <th className="text-center">Last Run</th>
                </tr>
              </thead>
              <tbody>
                {queuedProjects.length ? (
                  queuedProjects.map((projectOrCondition, index) => {
                    return (
                      <tr key={`queued-${projectOrCondition.id}-${index}`}>
                        <td>
                          <ExternalLink
                            url={
                              projectOrCondition.model_type === "BrandProject"
                                ? `/project/${projectOrCondition.uuid}`
                                : `/clusters/${projectOrCondition.uuid}`
                            }
                          >
                            {projectOrCondition.name}
                          </ExternalLink>
                        </td>
                        <td>
                          {projectOrCondition.parent_company_name ? (
                            <ExternalLink
                              url={`/company/${projectOrCondition.parent_company}`}
                            >
                              {projectOrCondition.parent_company_name}
                            </ExternalLink>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <ProjectDifficultyIcon
                            acnu_difficulty={projectOrCondition.acnu_difficulty}
                          />
                        </td>
                        <td>{formatDate(projectOrCondition.run_initiated)}</td>
                        <td>{formatDate(projectOrCondition.last_run)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data text-center">
                      No projects or conditions are currently queued.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        <section className="page-section in-progress">
          <div className="table-container overflow-x-scroll w-full">
            <table className="project-table status">
              <thead>
                <tr>
                  <th className="align-middle">
                    <h2>In Progress</h2>
                  </th>
                  <th>Company</th>
                  <th className="text-center">ACNU Difficulty</th>
                  <th className="text-center">Run Initiated</th>
                  <th className="text-center">Last Run</th>
                </tr>
              </thead>
              <tbody>
                {inProgressProjects.length ? (
                  inProgressProjects.map((projectOrCondition) => {
                    return (
                      <tr key={`progress-${projectOrCondition.id}`}>
                        <td>
                          <RefreshStatus
                            projectOrCondition_name={projectOrCondition.name}
                            status={projectOrCondition.ordered_analyses_status}
                            completion_percentage={
                              projectOrCondition.completion_percentage
                            }
                            completion_time={projectOrCondition.completion_time}
                          />
                          <ExternalLink
                            url={
                              projectOrCondition.model_type === "BrandProject"
                                ? `/project/${projectOrCondition.uuid}`
                                : `/clusters/${projectOrCondition.uuid}`
                            }
                          >
                            {projectOrCondition.name}
                          </ExternalLink>
                        </td>
                        <td>
                          {projectOrCondition.parent_company_name ? (
                            <ExternalLink
                              url={`/company/${projectOrCondition.parent_company}`}
                            >
                              {projectOrCondition.parent_company_name}
                            </ExternalLink>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <ProjectDifficultyIcon
                            acnu_difficulty={projectOrCondition.acnu_difficulty}
                          />
                        </td>
                        <td className="text-sm justify-center">
                          {formatDate(projectOrCondition.run_initiated)}
                        </td>
                        <td className="text-sm justify-center">
                          {formatDate(projectOrCondition.last_run)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data text-center">
                      No projects or conditions are currently being processed.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        <section className="page-section completed">
          <div className="table-container overflow-x-scroll w-full">
            <table className="project-table status complete">
              <thead>
                <tr>
                  <th className="align-middle">
                    <h2>Recently Completed</h2>
                  </th>
                  <th className="grid items-center">Company</th>
                  <th className="align-middle">ACNU Difficulty</th>
                  <th className="align-middle text-center">
                    Completion Time (Minutes)
                  </th>
                  <th className="align-middle text-center">Last Run</th>
                  <th className="align-middle text-center">Units Used</th>
                  <th className="align-middle text-center">Cost</th>
                </tr>
              </thead>
              <tbody>
                {successProjects.length ? (
                  successProjects.map((projectOrCondition, index) => {
                    return (
                      <tr key={`complete-${projectOrCondition.id}-${index}`}>
                        <td>
                          <RefreshStatus
                            project_name={projectOrCondition.name}
                            status={projectOrCondition.ordered_analyses_status}
                            completion_percentage={
                              projectOrCondition.completion_percentage
                            }
                            completion_time={projectOrCondition.completion_time}
                          />
                          <RefreshButton
                            refresh_property="project"
                            projectOrCondition={projectOrCondition}
                            updatingProjects={updatingProjects}
                            setUpdatingProjects={setUpdatingProjects}
                          />
                          <ExternalLink
                            url={
                              projectOrCondition.model_type === "BrandProject"
                                ? `/project/${projectOrCondition.uuid}`
                                : `/clusters/${projectOrCondition.uuid}`
                            }
                          >
                            {projectOrCondition.name}
                          </ExternalLink>
                        </td>
                        <td>
                          {projectOrCondition.parent_company_name ? (
                            <ExternalLink
                              url={`/company/${projectOrCondition.parent_company}`}
                            >
                              {projectOrCondition.parent_company_name}
                            </ExternalLink>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <ProjectDifficultyIcon
                            acnu_difficulty={projectOrCondition.acnu_difficulty}
                          />
                        </td>
                        <td className="justify-center">
                          {projectOrCondition.completion_time !== "N/A"
                            ? Number(
                                projectOrCondition.completion_time
                              ).toFixed(2)
                            : "N/A"}
                        </td>
                        <td className="text-sm justify-center">
                          {formatDate(projectOrCondition.last_run)}
                        </td>
                        <td className="justify-center">
                          {projectOrCondition.semrush_units_used.toLocaleString()}
                        </td>
                        <td className="justify-center">
                          ${projectOrCondition.latest_semrush_cost.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data text-center">
                      No projects have been processed.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        <section className="page-section stuck">
          <div className="table-container overflow-x-scroll w-full">
            <table className="project-table status">
              <thead>
                <tr>
                  <th className="align-middle">
                    <h2>Stuck</h2>
                  </th>
                  <th>Company</th>
                  <th className="text-center">ACNU Difficulty</th>
                  <th className="text-center">Run Initiated</th>
                  <th className="text-center">Last Run</th>
                </tr>
              </thead>
              <tbody>
                {stuckProjects.length ? (
                  stuckProjects.map((projectOrCondition) => {
                    return (
                      <tr key={`progress-${projectOrCondition.id}`}>
                        <td>
                          <RefreshStatus
                            project_name={projectOrCondition.name}
                            status={projectOrCondition.ordered_analyses_status}
                            completion_percentage={
                              projectOrCondition.completion_percentage
                            }
                            completion_time={projectOrCondition.completion_time}
                          />
                          <RefreshButton
                            project_name={projectOrCondition.name}
                            refresh_property="project"
                            projectOrCondition={projectOrCondition}
                            updatingProjects={updatingProjects}
                            setUpdatingProjects={setUpdatingProjects}
                          />
                          <ExternalLink
                            url={
                              projectOrCondition.model_type === "BrandProject"
                                ? `/project/${projectOrCondition.uuid}`
                                : `/clusters/${projectOrCondition.uuid}`
                            }
                          >
                            {projectOrCondition.name}
                          </ExternalLink>
                        </td>
                        <td>
                          {projectOrCondition.parent_company_name ? (
                            <ExternalLink
                              url={`/company/${projectOrCondition.parent_company}`}
                            >
                              {projectOrCondition.parent_company_name}
                            </ExternalLink>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <ProjectDifficultyIcon
                            acnu_difficulty={projectOrCondition.acnu_difficulty}
                          />
                        </td>
                        <td className="text-sm justify-center">
                          {formatDate(projectOrCondition.run_initiated)}
                        </td>
                        <td className="text-sm justify-center">
                          {formatDate(projectOrCondition.last_run)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data text-center">
                      No projects or conditions are currently stuck.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        <section className="page-section failed">
          <div className="table-container overflow-x-scroll w-full">
            <table className="project-table status failure">
              <thead>
                <tr>
                  <th>
                    <h2>Failed</h2>
                  </th>
                  <th>Company</th>
                  <th className="text-center">ACNU Difficulty</th>
                  <th className="text-center">Completion Time (Minutes)</th>
                  <th className="text-center">Last Run</th>
                </tr>
              </thead>
              <tbody>
                {failedProjects.length ? (
                  failedProjects.map((projectOrCondition, index) => {
                    return (
                      <tr key={`failure-${projectOrCondition.id}-${index}`}>
                        <td>
                          <RefreshStatus
                            project_name={projectOrCondition.name}
                            status={projectOrCondition.ordered_analyses_status}
                            completion_percentage={
                              projectOrCondition.completion_percentage
                            }
                            completion_time={projectOrCondition.completion_time}
                          />
                          <RefreshButton
                            refresh_property="project"
                            projectOrCondition={projectOrCondition}
                            updatingProjects={updatingProjects}
                            setUpdatingProjects={setUpdatingProjects}
                          />
                          <ExternalLink
                            url={
                              projectOrCondition.model_type === "BrandProject"
                                ? `/project/${projectOrCondition.uuid}`
                                : `/clusters/${projectOrCondition.uuid}`
                            }
                          >
                            {projectOrCondition.name}
                          </ExternalLink>
                        </td>
                        <td>
                          {projectOrCondition.parent_company_name ? (
                            <ExternalLink
                              url={`/company/${projectOrCondition.parent_company}`}
                            >
                              {projectOrCondition.parent_company_name}
                            </ExternalLink>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <ProjectDifficultyIcon
                            acnu_difficulty={projectOrCondition.acnu_difficulty}
                          />
                        </td>
                        <td className="justify-center">
                          {projectOrCondition.completion_time !== "N/A"
                            ? Number(
                                projectOrCondition.completion_time
                              ).toFixed(2)
                            : "N/A"}
                        </td>
                        <td className="text-sm justify-center">
                          {formatDate(projectOrCondition.last_run)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5" className="no-data text-center">
                      No projects or conditions have failed to process.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProjectStatus;
