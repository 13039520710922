import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { $axios } from '../../../services/axiosInstance';

const useStockData = (ticker) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!ticker) {
      setError("Company ticker is not available.");
      return;
    }

    // Fetch historical data
    const fetchHistoricalData = async () => {
      try {
        const response = await $axios.get(`/historical_polygon_data/${ticker}/`);
        setData(response.data.results);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchHistoricalData();

    // Initialize Socket.IO connection
    const socket = io("http://localhost:3002");

    socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
      // Subscribe to the ticker when connected
      socket.emit("subscribe", ticker);
    });

    socket.on("data", (incomingData) => {
      // Handle a server acknowledgment if needed
      if (incomingData.status === "ready_for_subscription") {
        socket.emit("subscribe", ticker);
        return;
      }
      // Process incoming candle data if event type is "A"
      if (incomingData.ev === "A") {
        setData((prevData) => {
          if (prevData.length === 0) return prevData;
          const lastCandle = prevData[prevData.length - 1];
          const nextCandleStartTime = lastCandle.t + 86400000; // 24 hours in ms

          if (incomingData.e >= nextCandleStartTime) {
            // Append a new candle
            return [
              ...prevData,
              {
                t: incomingData.e,
                o: incomingData.o,
                h: incomingData.h,
                l: incomingData.l,
                c: incomingData.c,
                v: incomingData.v,
                vw: incomingData.vw,
              },
            ];
          } else {
            // Update the existing candle
            return prevData.map((candle, index) => {
              if (index === prevData.length - 1) {
                return {
                  ...candle,
                  h: Math.max(candle.h, incomingData.h),
                  l: Math.min(candle.l, incomingData.l),
                  c: incomingData.c,
                  v: candle.v + incomingData.v,
                  vw:
                    (candle.vw * candle.v + incomingData.vw * incomingData.v) /
                    (candle.v + incomingData.v),
                };
              }
              return candle;
            });
          }
        });
      }
    });

    socket.on("error", (err) => {
      console.error("Socket.IO error:", err);
      setError(err);
    });

    // Clean up the socket connection on unmount or when ticker changes
    return () => {
      socket.disconnect();
    };
  }, [ticker]);

  return { data, loading, error };
};

export default useStockData;
