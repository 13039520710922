import { useState, useEffect } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";
import { generateGradientColors } from "../../../utils/chartUtils";
import { formatFloat, formatPercent } from "../../../utils/tableUtils.js";

import { useTheme } from "../../../utils/ThemeManager.jsx";

export const matchesQuery = (item, query) => {
  const lowerQuery = query.toLowerCase();
  const { title, summary, popular_questions, visualizations } = item.cluster_data;
  return (
    title.toLowerCase().includes(lowerQuery) ||
    (summary && summary.toLowerCase().includes(lowerQuery)) ||
    (popular_questions && popular_questions.some(q => q.question.toLowerCase().includes(lowerQuery))) ||
    (visualizations.keywords && visualizations.keywords.some(v => v.value.toLowerCase().includes(lowerQuery)))
  );
};

export const labelFormatter = function () {
  const value = this.value;
  return value > 9999
    ? `${(value / 1000).toFixed(0)}K` // Convert to "10K", "20K", etc.
    : Highcharts.numberFormat(value, 0); // Use comma formatting for smaller numbers
};

export const tooltipFormatter = function () {
  const pointData = this.point.options || this.point;

  return `<div style="display: flex; flex-direction: column;">
    <strong style="margin-bottom: 4px">${pointData.title}</strong>
    <div style="display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr; column-gap: 8px">
      <div>Monthly Volume:</div> <div>${formatFloat(pointData.x, 0)}</div>
      <div>KWD // KWO:</div> <div>${formatPercent(pointData.kwDifficulty, 2)} // ${formatFloat(pointData.kwOpportunity, 2)}</div>
    </div>
  </div>`;
};

export const handlePointClick = function (clusterId) {
  return function () {
    if (this.uuid) {
      window.open(`/cluster/${clusterId}/${this.uuid}`, "_blank");
    }
  };
};

// data: // data: cluster.visualization.search_volume_by_type
// Search Volume by Month by Type
const SearchVolMonth = ({ data, model_name, clusterId, searchQuery }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(chartOptions(data, model_name, theme, clusterId, searchQuery));
  }, [theme, data, searchQuery]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <p className="min-h-[450px] px-4 py-8 font-bold grid place-items-center">
        Monthly Search Volume data is not available.
      </p>
    );
  }

  return (
    <ChartDashboard
      config={options}
      async={true}
      id="monthly-search-volume-bubble"
    />
  );
};

export default SearchVolMonth;

const chartOptions = (data, model_name, theme, clusterId, searchQuery) => {
  const queries = searchQuery ? searchQuery.split(',').map(q => q.trim().toLowerCase()).filter(query => query.length > 0) : [];

  const colors = {
    dark: queries.length < 1
      ? ['#FF3C6D']
      : generateGradientColors('#FF3C6D', '#FF7347', queries.length),
    light:queries.length < 1
      ? ['#0561B7']
      : generateGradientColors('#020021', '#0561B7', queries.length),
  }

  const seriesData = queries.length > 0
  ? queries.map((query, index) => ({
      name: query,
      data: data.filter(item => matchesQuery(item, query)),
      marker: { fillOpacity: 0.6 },
      color: colors[theme][index],
    }))
  : [{
      name: "Cluster Share",
      data: data,
      marker: { fillOpacity: 0.6 },
      colorByPoint: true,
    }];

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "monthly-search-volume-bubble-cell",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "monthly-search-volume-bubble-cell",
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: "bubble",
            zoomType: "xy",
            plotBorderWidth: 1,
            styledMode: false,
            height: 450,
          },
          title: {
            text: "Monthly Search Volume by Type",
          },
          subtitle: {
            text: `${ model_name ? `Project: ${model_name} | ` : '' }Source: SEMRush`,
          },
          legend: {
            enabled: queries.length > 0,
          },
          xAxis: {
            reversed: true,
            title: {
              text: "Average Monthly Search Volume",
            },
            labels: {
              formatter: labelFormatter,
            },
          },
          yAxis: {
            reversed: false,
            title: {
              text: "Keyword Difficulty",
            },
          },
          tooltip: {
            useHTML: true,
            formatter: tooltipFormatter,
          },
          series: seriesData,
          plotOptions: {
            bubble: {
              minSize: 3,
              maxSize: 50,
              point: {
                events: {
                  click: handlePointClick(clusterId),
                },
              },
            },
          },
          annotations: [
            {
              labels: [
                {
                  point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 16800,
                    y: 98,
                  },
                  text: "Search Volume",
                },
              ],
              draggable: "",
            },
            {
              labels: [
                {
                  point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 4000,
                    y: 25,
                  },
                  text: "Low Difficulty",
                },
              ],
              labelOptions: {
                style: {
                  fontWeight: "bold",
                },
              },
              draggable: "",
            },
          ],
        },
      },
    ],
  };
};