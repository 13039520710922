import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../context/Config'

const Auth0ProviderWithHistory = ({ children }) => {
  // const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  // const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const {isLoading, auth0_client_id, auth0_domain} = useConfig()
  const navigate = useNavigate()

  if (isLoading) {
    return <>{children}</>
  }

  const onRedirectCallback = (appState) => {
    const rp = appState?.returnTo || sessionStorage.getItem("redirectPath") || "/leaderboard"
    navigate(rp)
  };

  return (
    <Auth0Provider
      domain={auth0_domain}
      clientId={auth0_client_id}
      authorizationParams={{
        redirect_uri: window.location.origin + '/callback',
        audience: `https://${auth0_domain}/api/v2/`,
        scope: "openid profile email"
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
