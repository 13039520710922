import {useState, useEffect} from 'react';

import {loadStripe} from '@stripe/stripe-js';

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';

import { useConfig } from "../../context/Config"

function CheckoutForm({fetchClientSecret}) {
  const [stripePromise, setStripePromise] = useState()
  const [options, setOptions] = useState(null)
  const { stripe_publishable_key } = useConfig()

  useEffect(() => {
    if (!stripe_publishable_key) {
      return
    }

    setStripePromise(loadStripe(stripe_publishable_key))

    setOptions({
      fetchClientSecret,
    })
  }, [stripe_publishable_key]);

  return (
    <div className="checkout-form">
      { stripePromise && options && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={options}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

export default CheckoutForm;
